/* day view start */
:root {
  --button-background-color: #2c7be5;
  --background-color: #1b2dcb;
}

.day-view .rbc-time-gutter .rbc-label {
  color: #748194;
  font-size: 12px;
}
.day-view .rbc-time-gutter .rbc-timeslot-group {
  border-bottom: 0px solid #ddd;
  width: 78px !important;
}
.day-view .rbc-time-gutter .rbc-time-slot {
  flex-direction: column;
  align-items: flex-end;
  text-align: center;
}
.day-view .rbc-time-gutter {
  border-right: 1px solid #ebeaed;
}
.day-view .rbc-time-view-resources .rbc-time-header-gutter {
  border-right: 1px solid #ebeaed;
}
.day-view .rbc-today {
  background-color: #ffffff;
}
.day-view .rbc-time-header .rbc-label {
  max-width: 79px !important;
  width: 79px !important;
  min-width: 79px !important;
  border-top: 1px solid #eceff0;
}
.day-view .rbc-time-content > * + * > * {
  border-left: 1px solid #eceff0;
}
.day-view .rbc-timeslot-group {
  border-bottom: 1px solid #eceff0;
}
.day-view .rbc-time-header.rbc-overflowing {
  border-right: 1px solid #eceff0;
}
.day-view .rbc-time-content {
  border-top: 1px solid #eceff0;
  border-radius: 8px;
}
.day-view .rbc-time-header-content {
  border-left: 1px solid #eceff0;
}
.day-view .rbc-time-view {
  border: 0px solid #ddd;
}
.day-view .rbc-allday-cell {
  display: block;
}

.day-view .rbc-time-view .rbc-row {
  min-height: auto;
}

.rbc-calendar {
  margin: 0px 16px 20px 0px;
  margin-top: 0px;
  box-shadow: 0px 1px 8px rgb(20 46 110 / 10%);
  border-radius: 8px;
  background: white;
  padding-bottom: 30px;
  min-height: 300px;
}
.day-view .rbc-header {
  padding: 0px;
  border-bottom: 0px solid #ddd;
  font-size: 14px;
  padding-top: 0px;
  padding-bottom: 0px;
  font-weight: 400;
  text-transform: uppercase;
}
.day-view .rbc-time-header-gutter {
  justify-content: center;
  align-items: center;
  display: flex;
}

.day-view .rbc-time-header.rbc-overflowing {
  margin-right: 8px !important;
}
.day-view .rbc-time-header-content > .rbc-row.rbc-row-resource {
  border-bottom: 0px solid #ddd;
}

#calender-selector .ant-select-selector {
  border: 0px;
}
#calender-selector .ant-select-selection-item {
  background: #ebf3ff;
  border-radius: 4px;
  height: 32px;
}
#calender-selector .ant-select-selection-item-content {
  color: var(--button-background-color);
  font-weight: 500;
  margin-top: auto;
  margin-bottom: auto;
}
#calender-selector .ant-select-selection-item-remove {
  user-select: none;
  color: #2d3748;
  margin-top: 4px;
  margin-bottom: auto;
}
.rbc-day-slot .rbc-event-label {
  display: none;
}
/* day view end */
/* week view start */
.week-view .rbc-time-gutter .rbc-label {
  color: #748194;
  font-size: 12px;
}
.week-view .rbc-time-gutter .rbc-timeslot-group {
  border-bottom: 0px solid #ddd;
  width: 78px !important;
}
.week-view .rbc-time-gutter .rbc-time-slot {
  flex-direction: column;
  align-items: flex-end;
  text-align: center;
}
.week-view .rbc-today {
  background-color: #ffffff;
}
.week-view .rbc-time-header .rbc-label {
  max-width: 78px !important;
  width: 78px !important;
  min-width: 78px !important;
  border-top: 1px solid #eceff0;
  border-right: 0px solid #eceff0;
}
.week-view .rbc-time-content > * + * > * {
  border-left: 1px solid #eceff0;
}
.week-view .rbc-timeslot-group {
  border-bottom: 1px solid #eceff0;
}
.week-view .rbc-day-slot .rbc-time-slot {
  border-top: 0px solid #f7f7f7;
}
.week-view .rbc-time-header.rbc-overflowing {
  border-right: 1px solid #eceff0;
}
.week-view .rbc-time-content {
  border-top: 1px solid #eceff0;
  border-radius: 8px;
}
.week-view .rbc-time-header-content {
  border-left: 1px solid #eceff0;
  border-top: 1px solid #eceff0;
}
.week-view .rbc-time-view {
  border: 0px solid #ddd;
}
.week-view .rbc-allday-cell {
  display: block;
}
.week-view .rbc-time-view .rbc-row {
  min-height: auto;
}

.week-view .rbc-header {
  padding: 0px;
  border-bottom: 0px solid #eceff0;
}
.week-view .rbc-row-resource .rbc-header {
  padding: 0px;
  border-bottom: 1px solid #eceff0;
}
.week-view .rbc-time-header-gutter {
  justify-content: center;
  align-items: center;
  display: flex;
}
.week-view .rbc-header + .rbc-header {
  border-left: 0px solid #ddd;
}

.week-view .rbc-time-header.rbc-overflowing {
  margin-right: 15px !important;
}
.week-view .rbc-time-header-content > .rbc-row.rbc-row-resource {
  border-bottom: 0px solid #ddd;
}
.week-view .rbc-time-header-cell .rbc-header button {
  text-transform: uppercase;
  pointer-events: none;
}
.week-view .rbc-time-header-cell .rbc-header button .today {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  color: #1a73e8;
  pointer-events: none;
}

.week-view .day-name {
  display: none;
}
.week-view .rbc-time-gutter {
  border-right: 1px solid #ebeaed;
}
.week-view .rbc-time-content > .rbc-time-gutter {
  border-right: 0px solid #ebeaed;
}

/* week view end */

/* month view start*/
.month-view .rbc-time-gutter .rbc-label {
  color: #748194;
  font-size: 12px;
}
.month-view .rbc-time-gutter .rbc-timeslot-group {
  border-bottom: 0px solid #ddd;
  width: 78px !important;
}
.month-view .rbc-time-gutter .rbc-time-slot {
  flex-direction: column;
  align-items: flex-end;
  text-align: center;
}
.month-view .rbc-today {
  background-color: #ffffff;
}
.month-view .rbc-time-header .rbc-label {
  max-width: 79px !important;
  width: 79px !important;
  min-width: 79px !important;
  border-top: 1px solid #eceff0;
  border-right: 1px solid #eceff0;
}
.month-view .rbc-time-content > * + * > * {
  border-left: 1px solid #eceff0;
}
.month-view .rbc-timeslot-group {
  border-bottom: 1px solid #eceff0;
}
.month-view .rbc-time-header.rbc-overflowing {
  border-right: 1px solid #eceff0;
}
.month-view .rbc-time-content {
  border-top: 1px solid #eceff0;
  border-radius: 8px;
}
.month-view .rbc-time-header-content {
  border-left: 1px solid #eceff0;
}
.month-view .rbc-time-view {
  border: 0px solid #ddd;
}
.month-view .rbc-allday-cell {
  display: block;
}
.month-view .rbc-time-view .rbc-row {
  min-height: auto;
}

.month-view .rbc-header {
  padding: 0px;
  border-bottom: 0px solid #eceff0;
  text-transform: uppercase;
}
.month-view .rbc-row-resource .rbc-header {
  padding: 0px;
  border-bottom: 1px solid #eceff0;
}
.month-view .rbc-time-header-gutter {
  justify-content: center;
  align-items: center;
  display: flex;
}
.month-view .rbc-header + .rbc-header {
  border-left: 0px solid #ddd;
}

.month-view .rbc-time-header.rbc-overflowing {
  margin-right: 15px !important;
}
.month-view .rbc-time-header-content > .rbc-row.rbc-row-resource {
  border-bottom: 0px solid #ddd;
}
.month-view .rbc-time-header-cell .rbc-header button {
  text-transform: uppercase;
}
.month-view .rbc-time-header-cell .rbc-header button .today {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  color: #1a73e8;
}

.month-view .day-name {
  display: none;
}
.month-view .rbc-now button {
  color: #1a73e8;
  font-weight: 600;
}
.month-view .rbc-time-gutter {
  border-right: 1px solid #ebeaed;
}
.month-view .rbc-time-content > .rbc-time-gutter {
  border-right: 1px solid #ebeaed;
}
.month-view .rbc-header {
  font-size: 14px;
  padding-top: 0px;
  padding-bottom: 0px;
  font-weight: 400;
}
.month-view .rbc-month-header {
  font-size: 14px;
  padding-top: 9px;
  padding-bottom: 9px;
  font-weight: 400;
  border-bottom: 1px solid #eceff0;
}
.rbc-month-header {
  font-size: 14px;
  padding-top: 9px;
  padding-bottom: 9px;
  font-weight: 400;
  border-bottom: 1px solid #eceff0;
}
/* .rbc-month-view {
  border: 1px solid #eceff0;
} */
.month-view .rbc-month-row + .rbc-month-row {
  border-top: 1px solid #eceff0;
}
.month-view .rbc-off-range-bg {
  background: white !important;
}
.day-view .rbc-off-range-bg {
  background: white !important;
}
.month-view .rbc-off-range {
  color: #ececec !important;
}
.month-view .rbc-month-view {
  border: 1px solid #eceff0;
}
.month-view .rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #eceff0;
}
.month-view .rbc-date-cell {
  color: #a2b2c2;
  text-align: initial;
  padding-left: 5px;
  padding-top: 5px;
}
/* month view end */

/* react small calender start */
.react-calendar__month-view__weekdays__weekday {
  padding: 0px;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
}
.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none !important;
}
.react-calendar__month-view__days__day {
  color: #5e6e82;
  font-size: 12px;
}
.react-calendar__month-view__days__day:hover {
  background: #e8eaed;
  border-radius: 16px;
}
.react-calendar__tile {
  background-color: white;
  border: 0px;
  padding: 5px;
  cursor: pointer;
}
.react-calendar__tile--now {
  background-color: var(--button-background-color);
  color: #fff;
  border-radius: 50%;
}
.react-calendar__navigation__label {
  background-color: white;
  border: 0px;
  padding: 5px;
  cursor: pointer;
  font-weight: 500;
  font-size: 18px;
}
.react-calendar__navigation__arrow {
  background-color: white;
  border: 0px;
  padding: 5px;
  cursor: pointer;
}
.react-calendar__navigation {
  text-align: center;
  display: flex;
}
.react-calendar__navigation__prev2-button {
  display: block;
}
.react-calendar__navigation__next2-button {
  display: block;
}
.react-calendar__navigation__next-button svg {
  color: #5f6368;
}
.react-calendar__navigation__prev-button svg {
  color: #5f6368;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #bec1c3;
}
/* react small calender end */

#calender-selector .ant-select-clear {
  display: none;
}

.spinner-border {
  margin-top: auto;
  margin-bottom: auto;
}

/* exam view start */

.exam-view .rbc-time-gutter .rbc-label {
  color: #748194;
  font-size: 12px;
}
.exam-view .rbc-time-gutter .rbc-timeslot-group {
  border-bottom: 0px solid #ddd;
  width: 78px !important;
}
.exam-view .rbc-time-gutter .rbc-time-slot {
  flex-direction: column;
  align-items: flex-end;
  text-align: center;
}
.exam-view .rbc-time-gutter {
  border-right: 1px solid #ebeaed;
}
.exam-view .rbc-time-view-resources .rbc-time-header-gutter {
  border-right: 1px solid #ebeaed;
}
.exam-view .rbc-today {
  background-color: #ffffff;
}
.exam-view .rbc-time-header .rbc-label {
  max-width: 79px !important;
  width: 79px !important;
  min-width: 79px !important;
  border-top: 1px solid #eceff0;
}
.exam-view .rbc-time-content > * + * > * {
  border-left: 1px solid #eceff0;
}
.exam-view .rbc-timeslot-group {
  border-bottom: 1px solid #eceff0;
}
.exam-view .rbc-time-header.rbc-overflowing {
  border-right: 1px solid #eceff0;
}
.exam-view .rbc-time-content {
  border-top: 1px solid #eceff0;
  border-radius: 8px;
}
.exam-view .rbc-time-header-content {
  border-left: 1px solid #eceff0;
}
.exam-view .rbc-time-view {
  border: 0px solid #ddd;
}
.exam-view .rbc-allday-cell {
  display: block;
}

.exam-view .rbc-time-view .rbc-row {
  min-height: auto;
}

/* .rbc-calendar {
  margin: 0px 16px 20px 0px;
  margin-top: 0px;
  box-shadow: 0px 1px 8px rgb(20 46 110 / 10%);
  border-radius: 8px;
  background: white;
  padding-bottom: 30px;
} */
.exam-view .rbc-header {
  padding: 0px;
  border-bottom: 0px solid #ddd;
}
.exam-view .rbc-time-header-gutter {
  justify-content: center;
  align-items: center;
  display: flex;
}

.exam-view .rbc-time-header.rbc-overflowing {
  margin-right: 9px !important;
}
.exam-view .rbc-time-header-content > .rbc-row.rbc-row-resource {
  border-bottom: 0px solid #ddd;
}

#calender-selector .ant-select-selector {
  border: 0px;
}
#calender-selector .ant-select-selection-item {
  background: #ebf3ff;
  border-radius: 4px;
  height: 32px;
}
#calender-selector .ant-select-selection-item-content {
  color: var(--button-background-color);
  font-weight: 500;
  margin-top: auto;
  margin-bottom: auto;
}
#calender-selector .ant-select-selection-item-remove {
  user-select: none;
  color: #2d3748;
  margin-top: 4px;
  margin-bottom: auto;
}
.rbc-day-slot .rbc-event-label {
  display: none;
}
/* exam view end */

.facility-selector .ant-select-selection-item-remove {
  display: none;
}

.rbc-calendar.day-view,
.rbc-calendar.month-view,
.rbc-calendar.week-view,
.rbc-calendar.exam-view {
  height: calc(100vh - 83px) !important;
  margin-bottom: 0px !important;
}
.week-view .rbc-time-header-content .rbc-day-bg {
  border-top: 1px solid #eceff0;
}

.rbc-day-slot .rbc-events-container .rbc-event:first-child {
  width: 100% !important;
}
.rbc-day-slot .rbc-events-container {
  margin-right: 0px !important;
}
/* ----------- update by cheran ------------------*/
.facility-selector .ant-select {
  width: 100%;
}
.cal-sel-prvdr .ant-select {
  width: 100%;
}
.calender-resource .topNavBarAvatar {
  margin-right: 7px;
}
.alert-icons-bts {
  margin-bottom: 4px;
  margin-right: 5px;
}
.alert-icons-bts > span {
  font-size: 20px;
}
.add-facility-alert {
  color: #4b4b4b;
  margin-left: 0px;
  font-size: 16px;
}

/* ----------- update by cheran ------------------*/
/* 12-05-23 */
.rbc-row-segment {
  position: relative;
}

.rbc-overlay .rbc-event-content {
  position: relative;
  padding: 2px 0px;
}
.rbc-overlay .rbc-event {
  padding: 2px 0px;
  border-radius: 7px !important;
}
.rbc-overlay .month-view-overlay {
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
}
.week-view .day-overlay {
  bottom: 0 !important;
}
.day-view .day-overlay {
  /* bottom: 0 !important; */
}
.day-overlay .three-dots:hover {
}
.note-edit-div {
  display: none;
}
.exam-view .day-overlay {
  bottom: 0 !important;
}
.rbc-month-row .rbc-event {
  padding: 2px 0px;
}
.rbc-overlay {
  border-radius: 8px;
  z-index: 100 !important;
}
/* 12-05-23 */
.rbc-overlay-header {
  font-weight: 600;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 0px;
}
