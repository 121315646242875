:root {
  --button-background-color: #2c7be5;
  --background-color: #1b2dcb;
  --font-work-sans: "Work Sans", sans-serif;
}
pat_bal_main.schedule-title {
  margin-left: 25px;
}

.appt-block-main-bill {
  margin-top: 0px;
  margin-right: 20px;
}

.prob-head-title {
  font-size: 16px;
}

.ins-top-btn span:not(.ant-select-selection-search) {
  background-color: white;
}
.ins-bill-src .ui.icon.input {
  /* max-width: 230px; */
}
.val-mod-txt {
  font-size: 16px;
  margin: 16px 0px;
}
.ins-val-mod .modal-dialog {
  right: 30%;
}
.show-ins-no-err {
  color: green;
  font-size: 20px;
  font-weight: 600;
  border: 2px solid green;
  padding: 20px;
  text-align: center;
}
.ins-top-btn {
  padding-right: 0px !important;
}

.ins-top-btn select {
  /* border-left: 0px;
  color: #2066c2; */
  height: 36px;
}

.ins-top-btn select::before {
  color: #718096 !important;
}

.ins-cl-check {
  margin-top: 7px;
  margin-bottom: 0px;
}

.ins-cl-check label {
  margin-right: 20px;
  margin-top: -2px;
}

.ins-p-s .ant-input-search {
  height: 32px;
}

.ins-p-s .ant-input,
.ins-p-s .ant-input-search-button,
.ins-p-s .ant-picker {
  height: 38px !important;
  border-radius: 0.25rem;
}

.ins-p-s .ant-input-search-button {
  border-left: 0px !important;
  border-radius: 0.25rem;
}

.ins-p-s .ant-input-search-button svg {
  margin-top: -5px;
}

.sec-row-ins {
  /* /* margin-top: 12px !important; */
  margin-bottom: 20px !important;
}
.for-pat-bal-led {
  margin-top: 4px !important;
  margin-left: 4px !important;
}

.ins-cl-ex {
  height: 32px;
  color: #2d3748;
}

.display-btn-inc {
  background-color: #00bf9e;
  border-color: #00bf9e;
}

.radio-btn-inc {
  margin-left: auto !important;
  height: 36px !important;
  margin-right: 15px !important;
}

.radio-btn-inc label {
  height: 36px !important;
  padding-top: 2px;
}

.ins-clm-t-block {
  display: flex;
}

.ins-cml-t-f-b {
  margin-bottom: 0px;
}

.radio-btn-inc .ant-radio-button-wrapper:first-child {
  border-radius: 4px 0 0 4px;
}

.radio-btn-inc .ant-radio-button-wrapper:last-child {
  border-radius: 0 4px 4px 0;
}

.thrid-row-pay {
  text-align: justify;
  padding: 16px;
  font-size: 20px;
  color: #57bf7f;
}

.fee-search input {
  width: 220px !important;
}

.thrid-row-paybal {
  text-align: justify;
  padding: 15px;
  color: var(--button-background-color);
  background: rgb(32 102 194 / 4%);
  margin-top: 20px;
  margin-bottom: 15px;
}

.pat-bal-s-t1 svg {
  height: 20px;
  width: 20px;
}

.dol-pay {
  background-color: transparent !important;
  border-right: 0px !important;
}
.dol-pay-in {
  border-left: 0px !important;
}
.border {
  display: flex;
  border-radius: 3px;
}
.header {
  text-align: initial;
  font-size: 16px;
  font-weight: 500;
  padding-top: 9px;
}
.p-l-5 {
  padding-left: 5px !important;
}
.verti {
  padding-left: 45px;
  padding-right: 45px;
}
.carder {
  padding-top: 5px;
  padding-bottom: 5px;
}
.thead {
  font-size: 14px;
  color: #718096;
  font-weight: 400;
  line-height: 40.94px;
}
.tdata {
  font-size: 14px;
  color: #2d3748;
  font-weight: 400;
  line-height: 25.94px;
}
.pay-head {
  color: #2d3748;
  font-size: 16px;
  font-weight: 500;
  padding-left: 2.25%;
  padding-bottom: 1%;
}
.pa-head {
  color: #2d3748;
  font-size: 16px;
  font-weight: 500;
  padding-left: 29px;
  padding-bottom: 1%;
  padding-top: 12px;
}
.bth {
  width: 180px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  background-color: #ffffff;
}

.editt {
  margin-left: 69%;
}
.editer button {
  text-shadow: 0px 0px 0px rgb(0 0 0 / 25%) !important;
}
.edsave {
  margin-top: 0px !important;
  margin-left: 10px;
  height: 10px;
}
.editer {
  margin-left: 71%;
}
.input-td {
  width: 119px;
  height: 32px;
  border-radius: 4px;
}
.all-appt-inpt-width {
  height: 32px;
  border-radius: 4px;
  width: 70px;
}
.input-size {
  height: 32px !important;
  border-radius: 4px;
}
.box {
  border: 1px solid #cbd5e0;
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 0px;
  padding-right: 25px;
}
.boxe {
  padding-right: 25px !important;
}
.bg-back {
  background-color: #fcfcfc;
  padding-top: 12px;
  padding-bottom: 12px;
}
.tr-no {
  border-top: 0px solid #cbd5e0 !important;
}
.date-w {
  width: 7%;
}
.th-low {
  padding-left: 7px !important;
  padding-right: 1px !important;
}
/* jeyabalaji */
.pst-border {
  border: 1px solid rgb(226, 232, 237);
  margin: 2px 20.8px 66.5px;
  padding: 12px 0 56px;
  border-radius: 4px !important;
}
/* jeyabalaji */
.th-low1 {
  padding-left: 3px !important;
  padding-right: 20px !important;
}
.pader {
  padding-left: 20px;
  padding-right: 20px;
  /* padding-bottom: 12px; */
}
.ant-input {
  height: 24px !important;
  margin-top: 8px;
}
.ant-input-search-button {
  height: 32px !important;
}
.css-yk16xz-control {
  height: 32px !important;
}
.ant-picker {
  height: 32px !important;
}
.css-1wa3eu0-placeholder {
  top: 40% !important;
}
.css-1uccc91-singleValue {
  top: 40% !important;
}
.fontweight-500 {
  font-weight: 500 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.m-b-12 {
  margin-bottom: 12px !important;
}
.ralph-width {
  padding-left: 20px;
  /* padding-bottom: 12px; */
}
.mr-0 {
  margin: 0px;
}
.prob_top_layer {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 11px;
  padding-bottom: 10px;
}
.state_pad_head {
  margin-top: 12px;
}

.ant-input {
  height: 32px !important;
  margin-top: 0px;
}
.pat-headlist {
  background: #ffffff;
}
.schedule-row th {
  color: #000000 !important;
}

.bg-back {
  margin-top: 20px !important;
}
.w-15-p {
  width: 15%;
}
.m-t-16 {
  margin-top: 16px;
}
.bg_dat {
  border: 1px solid #d9d9d9 !important;
  background-color: white;
  height: 36px !important;
  border-radius: 6px !important;
  width: 70% !important;
}
.css-1okebmr-indicatorSeparator {
  width: 0px !important;
}
.w-15-p .css-yk16xz-control {
  height: 36px !important;
  border-radius: 6px;
}
.css-1wa3eu0-placeholder {
  font-size: 16px;
}
.m-r-7 {
  margin-right: 7px;
}
.m-l--19 {
  margin-left: -19px;
}
.m-l-18 {
  margin-left: 18px;
}
.bg_color .css-yk16xz-control {
  background-color: #f4f4f4 !important;
}
.progress_bar_totalbilled,
.progress_bar_insurance {
  width: 50%;
}
.progress_bar_totalbilled {
  margin-left: 1.1%;
}
.progress_bar_insurance {
  margin-right: 1.1%;
}
.two_progress_bar {
  display: flex;
  margin-top: 25px;
  margin-left: 25px;
  margin-right: 20px;
  margin-bottom: 25px;
}
.two_progress_bar .card {
  background: #ffffff;
  box-shadow: 0px 1px 8px rgb(20 46 110 / 10%);
  border-radius: 8px;
  border: none;
  height: 356px;
}
.two_progress_bar .card-body {
  overflow-x: hidden;
}
.two_progress_bar .card-header {
  background-color: #ffffff;
  text-align: left;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  padding-right: 80px;
}
.ins_title {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  margin-bottom: 25px;
  margin-top: 10px;
}
.ins_amt {
  margin-left: 10px;
  color: var(--button-background-color);
}
.progress_bar_insurance_ {
  height: 25px;
  border-radius: 4px;
  margin-bottom: 10px;
}
.bg-blue_ {
  background-color: #5542f6;
  opacity: 90%;
}
.bg-sea_blue {
  background-color: #00a5ff;
  opacity: 90%;
}
.bg-light_green {
  background-color: #20c9ac;
  opacity: 90%;
}
.bg-light_orange {
  background-color: #ffa043;
  opacity: 90%;
}
.bg-red_ {
  background-color: #fc3400;
  opacity: 90%;
}
.bg-light_pink {
  background-color: #fa699d;
  opacity: 90%;
}
.f-s-25-m-r-l {
  font-size: 25px;
  margin-left: -29px;
  margin-right: -18px;
}
.progress_bar_insurance .card-body {
  text-align: left;
  padding: 5px 21px 0px 0px;
}
.progress_bar_insurance .card-body .list-group-item {
  padding-left: 0.75rem;
  border: none !important;
  padding-top: 16px;
  padding-bottom: 0px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}
.progress_bar_insurance .card-body .list-group-item .list_icon_1 {
  font-size: 20px !important;
  color: #5542f6;
}
.progress_bar_insurance .card-body .list-group-item .list_icon_2 {
  font-size: 20px !important;
  color: #00a5ff;
}
.progress_bar_insurance .card-body .list-group-item .list_icon_3 {
  font-size: 20px !important;
  color: #20c9ac;
}
.progress_bar_insurance .card-body .list-group-item .list_icon_4 {
  font-size: 20px !important;
  color: #ffa043;
}
.progress_bar_insurance .card-body .list-group-item .list_icon_5 {
  font-size: 20px !important;
  color: #fc3400;
}
.progress_bar_insurance .card-body .list-group-item .list_icon_6 {
  font-size: 20px !important;
  color: #fa699d;
}
._amt_ {
  color: #2d3748;
  font-weight: 500;
  float: right;
}
.progress_bar_insurance .card-body .list-group {
  margin-bottom: 25px;
}
.progress_bar_totalbilled .card-header {
  border-bottom: none;
}

.progress_bar_totalbilled .card-body {
  text-align: left;
  padding: 5px 21px 30px 20px;
}
.bill_progress_ {
  display: flex;
}

.bs_bar_1 {
  height: 164px;
  width: 164px;
}
.bs_bar_1 .ant-progress-inner {
  width: 100% !important;
  height: 100% !important;
  padding: 5px !important;
}
.bs_bar_2 {
  height: 194px;
  width: 194px;
  margin-left: -179px;
}
.bs_bar_2 .ant-progress-inner {
  width: 100% !important;
  height: 100% !important;
  /* padding: 5px !important; */
}

.bs_bar_3 {
  height: 235px;
  width: 235px;
  margin-left: -214px;
}
.bs_bar_3 .ant-progress-inner {
  width: 100% !important;
  height: 100% !important;
  /* padding: 5px !important; */
}
.progress_bar_bill_list .list_icon_1 {
  font-size: 20px !important;
  color: #5542f6;
}
.progress_bar_bill_list .list-group .list-group-item {
  padding-left: 0.75rem;
  border: none !important;
  padding-top: 16px;
  padding-bottom: 0px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}
._circle_flow_chart {
  margin-left: 0% !important;
}
._circle_flow_chart .card {
  margin-right: 2.2% !important;
}
.progress_bar_bill_list {
  width: 50%;
}
.dx-c-bs4-container {
  height: 206px !important;
  width: 206px !important;
}
._circle_flow_chart .card {
  height: 288px;
}
._circle_flow_chart .card-body {
  text-align: left;
  padding: 0px 0px 0px 24px !important;
}
.dx-c-bs4-container {
  padding: 0px !important;
}
._circle_flow_chart .card-header {
  padding-bottom: 0px;
}
._circle_flow_chart .ins_title {
  margin-top: 12px;
  margin-bottom: 20px;
}
.Tot_bii_pie_chart {
  display: flex;
  width: 100%;
}
._circle_flow_chart .card-header {
  border-bottom: none;
}
.chart_heads .list_icon_1 {
  font-size: 20px !important;
  color: var(--button-background-color);
}
._circle_flow_chart .card-body .list-group-item .list_icon_2 {
  font-size: 20px !important;
  color: #f23e56;
}
._circle_flow_chart .card-body .list-group-item .list_icon_3 {
  font-size: 20px !important;
  color: #f87431;
}
._circle_flow_chart .card-body .list-group-item .list_icon_4 {
  font-size: 20px !important;
  color: #feb81c;
}
._circle_flow_chart .card-body .list-group-item .list_icon_5 {
  font-size: 20px !important;
  color: #1ed7a5;
}
._circle_flow_chart .card-body .list-group-item .list_icon_6 {
  font-size: 20px !important;
  color: #0cb1f2;
}
._circle_flow_chart .card-body .list-group-item {
  padding-left: 0.75rem;
  border: none !important;
  padding-top: 16px;
  padding-bottom: 0px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}
._circle_flow_chart {
  width: 50%;
}
.chart_heads ._amt_ {
  float: right;
}
.p-r-16 {
  padding-right: 16px;
}
._circle_flow_chart .card-body .chart-heads {
  width: 100% !important;
  padding-right: 16px;
}

.progress_bar_bill_bar {
  width: 50%;
}
.p-l-5--1 {
  margin-top: 1%;
  padding-left: 5px;
  padding-top: 2px;
  margin-bottom: 2%;
  padding-bottom: 12px;
  padding-top: 0.3px;
}
.p-r-20--1 {
  padding-right: 23px !important;
  margin-top: 1% !important;
  margin-bottom: 1% !important;
}
.p-l-5--4 {
  margin-bottom: 0%;
  padding-left: 5px;
}
.bg-back--5 {
  background-color: #fcfcfc;
  padding-top: 25%;
  padding-bottom: 25%;
}
.hik {
  padding-top: 2%;
}
.p-t-16--2 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.m-t-16--2 {
  margin-top: 12px;
}
.total-denial-analysis {
  display: flex;
  flex-direction: wrap;
}
.denl-analys-title {
  /* padding: 16px 20px 0px 20px ; */
  color: var(--button-background-color);
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}
.an-flex-center {
  display: flex;
  align-items: center;
}
.dn-an-scnd {
  width: 100%;
  background-color: #fcfcfc;
  padding: 0px 24px;
  flex-wrap: wrap;
}
.dn-an-scnd * {
  height: 32px;
}
.an-snd-inp {
  width: 160px;
  margin: 12px 24px 0px 0px;
}
.an-sel-box.form-control {
  height: 32px;
  color: #718096;
}
.an-scnd-text {
  margin: 10px 18px 0px 0px;
  padding-top: 6px;
}
.an-snd-srch {
  margin-top: 8px;
}
.an-snd-srch input {
  height: 32px;
  width: 160px;
  border: 1px solid #cbd5e0;
  border-radius: 4px;
  padding-left: 8px;
  margin: 2px 16px 0px 16px;
}
.an-snd-srch input::placeholder {
  color: #718096;
}
.an-snd-srch input::-ms-input-placeholder {
  color: #718096;
}
.an-snd-inp-grp {
  height: 32px;
  border: 1px solid #cbd5e0;
  margin-left: 16px;
  margin-top: 12px;
  border-radius: 4px;
}
.an-snd-date {
  width: 160px;
  margin: 12px 0px 0px 0px;
}
.an-sd-sel-text {
  padding-top: 8px;
  margin-left: 8px;
  font-size: 14px;
  color: #718096;
}

.an-sd-sel-box.form-control {
  border: none;
  height: 30px;
  padding-left: 0px;

  color: #3055fc;
}
.an-snd-ck-box {
  margin: 15px 20px 12px 0px;
}
/* .an-snd-ck-box.form-check {
  width: 240px;
  margin-left: 20px;
  margin-right: 8px;
} */
.an-chk-text {
  margin-top: 18px;
  padding-left: 5px;
}
/* .an-snd-ck-box .form-check-input {
  margin-bottom: 20px;
} */
.an-snd-btn1 {
  margin: 8px 0px 0px 18px;
}
.an-snd-btn1.btn-primary,
.an-snd-btn2.btn-success {
  height: 32px;
}
.an-snd-btn2 {
  margin: 8px 0px 0px 20px;
}
.two_progress_bar-1 {
  display: flex;
  margin-top: 50px;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 50px;
}
#ca41 {
  /* max-width:100% !important; */
  height: 36px !important;
  border-radius: 5px 5px 5px 5px !important;
  /* margin-right: -31%;
margin-left: -38%; */
  color: hsl(0, 0%, 50%) !important;
}

.svgclass > .recharts-surface {
  margin-top: 1%;
  margin-bottom: 7%;
  width: 100%;
  margin-left: 2%;
  margin-right: 2%;
}
.recharts-surface > .recharts-bar-rectangles {
  width: 10px !important;
  border-radius: 4px;
}
.input-size--7 {
  height: 36px !important;
  border-radius: 4px;
}
.pat_edu_title {
  width: 70%;
  text-align: left;
}

.pay_bar_chart {
  width: 100%;
  height: 490px;
}
.pay_bar_chart .recharts-wrapper {
  margin-top: 50px;
}
.recharts-cartesian-grid-vertical line {
  display: none;
}

.chart_data_title {
  width: 100%;
  display: flex;
  margin-top: 24px;
  margin-left: 25%;
}

.average_container {
  display: flex;
}

.average_ {
  height: 20px;
  width: 40px;
  border-radius: 4px;
  background-color: #57bf7f;
  cursor: pointer;
}

.average_title {
  color: #636d73;
  font-size: 14px;
  font-weight: 500;
  margin-left: 8px;
  cursor: pointer;
}

.totpay_container {
  display: flex;
  margin-left: 24px;
}

.totpay_ {
  height: 20px;
  width: 40px;
  border-radius: 4px;
  background-color: #826af9;
  cursor: pointer;
}

.totpay_title {
  color: #636d73;
  font-size: 14px;
  font-weight: 500;
  margin-left: 8px;
  cursor: pointer;
}

.inspaid_container {
  display: flex;
  margin-left: 24px;
}

.inspaid_ {
  height: 20px;
  width: 40px;
  border-radius: 4px;
  background-color: #d0aeff;
  cursor: pointer;
}

.inspaid_title {
  color: #636d73;
  font-size: 14px;
  font-weight: 500;
  margin-left: 8px;
  cursor: pointer;
}

.patpaid_container {
  display: flex;
  margin-left: 24px;
}

.patpaid_ {
  height: 20px;
  width: 40px;
  border-radius: 4px;
  background-color: #f7d2ff;
  cursor: pointer;
}

.patpaid_title {
  color: #636d73;
  font-size: 14px;
  font-weight: 500;
  margin-left: 8px;
  cursor: pointer;
}
.two_progress_bar-1 {
  display: flex;
  margin-top: 50px;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 50px;
}
#ca41 {
  /* max-width:100% !important; */
  height: 36px !important;
  border-radius: 5px 5px 5px 5px !important;
  /* margin-right: -31%;
margin-left: -38%; */
  color: hsl(0, 0%, 50%) !important;
}

.svgclass_7 > .recharts-surface {
  margin-top: 1%;
  margin-bottom: 7%;
  width: 100%;
  margin-left: 1%;
  margin-right: 1%;
}
.recharts-surface > .recharts-bar-rectangles {
  width: 10px !important;
  border-radius: 4px;
}
.input-size--7 {
  width: 100% !important;
  height: 36px !important;
  border-radius: 4px;
  padding-top: 3% !important;
  padding-bottom: 3% !important;
}
#r1 {
  padding-right: 15%;
  padding-left: 15%;
  border-radius: 5px;
  height: 36px !important;
  max-width: 100% !important;
  font-family: var(--font-work-sans);
  color: #718096 !important;
}
.r_5 > .input {
  font-family: var(--font-work-sans);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  color: #718096 !important;
}
.m-l-12a {
  margin-left: 19px !important;
}
.m-l-80_b {
  margin-left: 8%;
  padding-top: 2%;
  padding-bottom: 2%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 154% */

  /* Grey 1 */

  color: #2d3748;
}
.header_1 {
  text-align: initial;
  font-size: 14px;
  font-weight: 500;
  padding-top: 9px;

  font-family: var(--font-work-sans);
}

.py .css-1wa3eu0-placeholder {
  padding-right: 5%;
  padding-left: 5%;
  text-align: center;
  font-size: 14px !important;
  margin-left: 2px;
  margin-right: 2px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  box-sizing: border-box;
  font-family: var(--font-work-sans);
}
.m-l-12a input::placeholder {
  color: #718096 !important;
}
.r-3 .input > .placeholder {
  color: yellow !important;
}
.w-15-p_p {
  margin-left: 1%;
  /* padding-left: 2%; */
  width: 15%;
}

.w-15-p_p .css-yk16xz-control {
  min-height: 36px !important;
}
.recharts-tooltip-wrapper {
  height: 10vh !important;
  width: 150px !important;
  border-radius: 10% !important;
}
.recharts-tooltip-wrapper-right {
  border-radius: 10px !important;
}
.recharts-tooltip-wrapper-bottom {
  border-radius: 10px !important;
}
/* class="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom" */
.recharts-cartesian-grid-vertical line {
  display: none;
}

.i_1 .ui.search > .results {
  display: none;
  position: absolute;
  top: 100%;
  width: 96%;
  left: 1px !important;
  transform-origin: center top;
  white-space: normal;
  text-align: left;
  text-transform: none;
  background: #ffffff;
  margin-top: 0.5em;
  width: 16.7em;
  border-radius: 0.28571429rem;
  box-shadow: 0 2px 4px 0 rgb(34 36 38 / 12%), 0 2px 10px 0 rgb(34 36 38 / 15%);
  border: 1px solid #d4d4d5;
  z-index: 998;
}
.i_1 .ui.search > .results > .message .header {
  /* width: 80%; */
  font-family: var(--font-work-sans);
  font-size: 1rem;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.87);
  padding-top: 2%;
  padding-bottom: 2%;
}
.i_2 .ui.search > .results {
  display: none;
  position: absolute;
  top: 100%;
  width: 92%;
  transform-origin: center top;
  white-space: normal;
  text-align: left;
  text-transform: none;
  background: #ffffff;
  margin-top: 0.5 em;
  width: 16.7 em;
  border-radius: 0.28571429 rem;
  box-shadow: 0 2px 4px 0 rgb(34 36 38 / 12%), 0 2px 10px 0 rgb(34 36 38 / 15%);
  border: 1 px solid #d4d4d5;
  z-index: 998;
}
.i_2 .ui.search > .results > .message .header {
  margin-left: 2% !important;
  padding-top: 2%;
  padding-bottom: 2%;
}
.i_1 .ui.icon.input > input {
  background-color: #ffffff !important;
  padding-right: 2.67142857em !important;
  padding-left: 2.67142857em !important;
  color: #000000 !important;
}
.i_2 .ui.icon.input > input {
  background-color: #ffffff !important;
  padding-right: 2.67142857em !important;
  padding-left: 2.67142857em !important;
  color: #000000 !important;
}
.i_1 .ui.search > .results .result {
  background-color: #ffffff !important;
}
.ui.search .results .result:hover {
  background: #f9fafb !important;
}

.ui.search .results .result:hover > .content {
  background: #f9fafb !important;
}
#string {
  color: black !important;
}

.recharts-cartesian-axis-tick-value {
  font-family: var(--font-work-sans);
  fill: #505d6f !important;
}
.recharts-tooltip-wrapper-top {
  border-radius: 8px !important;
}
.recharts-layer > .recharts-bar-rectangle {
  border-radius: 70px !important;
}
/* .vo_1_1 .ui.search>.results{
max-width:100% !important;
} */
.ui.search > .results > .message .header {
  padding-top: 0% !important;
}

.recharts-default-tooltip {
  font-family: var(--font-work-sans);
  height: 37px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  white-space: nowrap;
  border-radius: 7px;
}
.recharts-tooltip-label {
  line-height: 1% !important;
  padding-top: 1px !important;
  margin-top: 1px;
  padding-bottom: 1px;
  margin-bottom: 1px;
}
.code_container {
  display: flex;
}

.code_ {
  height: 20px;
  width: 40px;
  border-radius: 4px;
  background-color: #2d99ff;
  cursor: pointer;
}

.code_title {
  color: #636d73;
  font-size: 14px;
  font-weight: 500;
  margin-left: 8px;
  cursor: pointer;
}
.reason_container {
  display: flex;
}

.reason_ {
  margin-left: 15px;
  height: 20px;
  width: 40px;
  border-radius: 4px;
  background-color: #fff2d4;
  cursor: pointer;
}

.reason_title {
  color: #636d73;
  font-size: 14px;
  font-weight: 500;
  margin-left: 8px;
  cursor: pointer;
}
.payer_container {
  display: flex;
}

.payer_ {
  margin-left: 14px;
  height: 20px;
  width: 40px;
  border-radius: 4px;
  background-color: #2cd9c5;
  cursor: pointer;
}

.payer_title {
  color: #636d73;
  font-size: 14px;
  font-weight: 500;
  margin-left: 8px;
  cursor: pointer;
}
.co-re-pa {
  margin-right: 12% !important;
  float: right !important;
  text-align: right !important;
  max-width: 100% !important;
}
.header_co_re {
  text-align: initial;
  font-size: 16px;
  font-weight: 500;
  padding-top: 9px;
  line-height: 19px;
  color: var(--button-background-color);
}
.c_r_check {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #2d3748;
}
.c--r---p {
  margin-left: -6px;
}
.f-s-25-m-r-le {
  font-size: 25px;
  margin-left: -16px;
  margin-right: -9px;
}
.radial_bar {
  width: 55%;
  margin-top: -90px;
}

.radial_bar .recharts-responsive-container .recharts-wrapper svg {
  width: 90% !important;
  height: 355px !important;
  position: absolute;
  margin-left: -18px !important;
}
.progress_bar_totalbilled .card-body .list-group {
  margin-top: 38px;
}
.progress_bar_totalbilled .card-body .list-group-item {
  padding-left: 0.75rem;
  border: none !important;
  padding-top: 16px;
  padding-bottom: 0px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}
.list_icon_1_ {
  font-size: 20px !important;
  color: #3e8cf4 !important;
}
.list_icon_2_ {
  font-size: 20px !important;
  color: #77b1ff !important;
}
.list_icon_3_ {
  font-size: 20px !important;
  color: #77b1ff !important;
}
.recharts-sector .recharts-radial-bar-sector {
  display: none !important;
}
.recharts-layer text {
  fill: #2d3748 !important;
  font-size: 18px;
}

#tipey {
  margin-left: 14px;
  height: 20px;
  width: 40px;
  border-radius: 4px;
  background-color: #2cd9c5;
}
/* .bg-back--5 {
  background-color: #fcfcfc;
  padding-top: 25%;
  padding-bottom: 25%;
} */
.footer_1_1 {
  grid-column-start: 2;
  /* background: white; */
}
/* .recharts-wrapper>.svgclass_7{
max-Width: 100% !important;
  position: relative;
  cursor: default;
   height: 470px;
} */
.svgclass_8 > .recharts-surface {
  margin-top: 1%;
  margin-bottom: 7%;
  width: 100%;
  margin-left: 1%;
  margin-right: 1%;
}
.co-re-p1 {
  max-width: 100% !important;
}
.uvuau {
  float: right !important;
  max-width: 100% !important;
}
.vo_1_1 .ui.icon.input > input {
  background-color: #fff !important;
}
.bg-back_ins_claim {
  max-width: 100% !important;
  background-color: #fcfcfc;
  padding-top: 25px;
  padding-bottom: 25px;
}
.is_cla {
  max-width: 100% !important;
}
.is_cla .form-control {
  height: 38px !important;
  width: 182px !important;
  /* width: 100% !important; */
}
.is_cla_1 {
  margin-left: 10px !important;
  margin-right: 10px !important;
  max-width: 100% !important;
}
.is_cla_1 .form-control {
  height: 38px !important;
  width: 182px !important;
}
/* .in_clam_cal_bt{

  max-width: 100% !important;

} */
.in_clam_cal_bt > .ant-picker {
  width: 100% !important;
  background-color: #fff !important;
  height: 39px !important;
}
.s_b_s {
  margin-left: 1% !important;
  margin-right: 1% !important;
  height: 38px !important;
  padding-top: 0.4% !important;
  padding-bottom: 0.4% !important;
  width: 11% !important;
}
.p_p-t_1 {
  background-color: #f1f5f8 !important;
  padding-top: 1% !important;
  padding-bottom: 1% !important;
}

.p_s_t .ui.icon.input > input {
  background: #ffffff !important;
}

.input-size_p_s {
  height: 38px !important;
}
.p-date_p .form-control {
  height: 38px !important;
}
.p__s_t_sa {
  height: 38px !important;
}
.pa_b_l .ui.icon.input > input {
  background-color: #fff !important;
}
.i_p_s .ui.icon.input > input {
  background: #ffffff !important;
}
.e_t_f {
  background: #ffffff !important;
}
.p_s_t .ui.search > .results {
  width: 96% !important;
}
.i_p_s .ui.search > .results {
  width: 96% !important;
}
.i_p_e_1 ui.search > .results {
  width: 96% !important;
}
.i_p_e_1 .ui.icon.input > input {
  background: #ffffff !important;
}
.u_b_h {
  height: 38px !important;
  width: 7% !important;
}
.f_s_i_d .ui.icon.input > input {
  background: #ffffff !important;
}
.pa_b_l .ui.search > .results {
  margin-left: 2% !important;
  margin-right: 2% !important;
  width: 16.3em !important;
}
.patient_statement__ {
  margin-top: 16px !important;
  padding-top: 0px !important;
  margin-bottom: 16px !important;
  padding-bottom: 0px !important;
}
/* .ins-top-btn__7{
    max-width: 100% !important;
} */
#i_c_t {
  max-width: 100% !important;
}
/* .insurance_claim_select{

} */
.insurance_claim___ {
  width: 15% !important;
  height: 38px !important;
}
.bg-back__p_l {
  margin-top: 1% !important;
  background-color: yellow;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: 1% !important;
}
.m-b-12_fee_sched {
  margin-bottom: 12px !important;
  margin-top: 12px !important;
  margin-left: 4px !important;
}
.for-pat-stat-head {
  margin-top: 13px !important;
  margin-bottom: 10px !important;
  margin-left: 4px !important;
}
.bg-back_fee_sched_background {
  margin-top: 16px;
  margin-bottom: 16px;
  background-color: #fcfcfc;
  padding-top: 8px;
  padding-bottom: 8px;
}
.for-icd9-head {
  margin-top: 16px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  margin-bottom: 16px !important;
}
.bg-back_export_billing_documents_back {
  background-color: #fcfcfc !important;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: 20px !important;
}
.date_exp_bill {
  border: 0pxsolid #d9d9d9 !important;
  background-color: #ffffff !important;
}
.bg_document {
  background-color: #ffffff !important;
  border-radius: 0px;
  height: 30px;
}
.create-appt_bill_doc {
  width: 9% !important;
  background-color: var(--button-background-color);
  font-weight: 500 !important;
}
.eob_save {
  width: 100px !important;
  height: 32px;
}
.eob_header {
  background-color: #fcfcfc;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: 25px !important;
}
.avg_pay_search {
  margin-top: 4px;
  margin-left: 25px;
  padding-right: 18px;
  padding-left: 18px;
  font-weight: 600;
  line-height: 24px;
}
.det_tot_row {
  background-color: #f1f7ff;
}
.det_tot_row td {
  font-weight: 500;
}
.insurance_claim_form_1 .form-control {
  /* width: 183px !important; */
  height: 34px !important;
  background-color: #fff;
}
.insurance_claim_form_2 .form-control {
  /* width: 183px !important; */
  height: 34px !important;
  background-color: #fff;
}
.insurance_claim_date .ant-picker {
  height: 34px !important;
  background-color: #fff;
}
.vo_1_1 .ui.input > input {
  background-color: #fff;
  height: 34px !important;
  /* width: 200px !important; */
}
.vo_1_1 .ui.search > .results {
  /* width: 200px !important; */
}
.ins_claim_title_color {
  color: var(--button-background-color);
}
#fullscreen-popup {
  width: 100% !important;
  max-width: none !important;
  height: 100% !important;
  margin: 0 !important;
  top: 0;
}

#fullscreen-popup .modal-content {
  /* height: 100vh !important; */
  border: 0 !important;
  border-radius: 0 !important;
  background-color: #f1f5f8;
}

.modal-bg.invoice-full {
  background-color: #f1f5f8 !important;
}
.bill-state-table .modal-body {
  font-size: 18px;
}
.modal-bg {
  background-color: #cce2ff;
}
.bill-full-from {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  padding: 50px 75px 0px 75px;
}
.statement-table {
  height: 150px;
  width: 500px;
  border: 2px solid #2d3748;
}
.statement-table .schedule-head-row > th {
  padding: 8px 20px 8px 20px;
  font-weight: 600;
}

.bill-total-table .schedule-head-row > th {
  padding: 8px 20px 8px 20px;
  font-weight: 600;
}
.sch-tb-head {
  background-color: #f3f3f3;
}
.pro-name-snd {
  font-weight: 600px;
  color: #3055fc;
}
.note-pro-head {
  font-size: 16px;
  font-weight: 600;
}
.note-pro-detail {
  margin-left: 25px;
}
.bill-tb-one {
  width: 180px;
}

.bill-tb-two {
  border-right: 2px solid #2d3748;
}
.pro-name {
  font-weight: 600;
  font-size: 16px;
}

.bill-tb-one {
  border-right: 2px solid #2d3748;
}

.billing-statebox {
  display: flex;
  margin: 0px 50px;
}
.bill-pat-name1 {
  flex-grow: 1.5;
  border: 2px solid #2d3748;
  color: #2d3748;
}
.bill-pat-name2 {
  flex-grow: 1;
  border: 2px solid #2d3748;
}
.super-pro-head,
.super-pat-head {
  width: 100%;
  border-top: 2px solid #2d3748;
  border-bottom: 2px solid #2d3748;
  background-color: #ebebeb;
}
.super-pro-data {
  margin: 16px 0px;
}
.super-pat-data {
  margin-bottom: 16px;
}
.super-bill-label {
  font-weight: 600;
}
.super-pro-detail1,
.super-pro-detail2,
.super-facility,
.super-pat-detail1,
.super-pat-detail2 {
  display: inline-block;
  margin-right: 300px;
}
.super-legnd {
  font-weight: 600;
  color: #2d3748;
}
.super-bill-final {
  display: flex;
  margin: 35px 0px;
  justify-content: space-between;
  width: 500px;
}
.super-bill-top {
  width: 100%;
  display: flex;
  align-items: center;
}
#fullscreen-popup .pat_statement_header .close {
  float: left;
  background-color: #ffffff !important;
  margin-top: 3px;
}

.m-r-220 {
  margin-right: 220px;
}

.patient_statement_body_div {
  height: 100%;
  background-color: #f1f5f8;
  width: 900px;
  margin: 20px auto;
}
.ck .ant_chk {
  margin-top: 0px !important;
}
.form-group .ant_chk_stbal {
  margin-top: 5px !important;
}
.era-search-box {
  display: flex;
  align-items: center;
  padding: 16px 20px 16px 0px;
  margin: 12px 0px 12px 20px;
  width: 100%;
}
.era-search-box .btn {
  margin-right: 10px;
}

.era-froms {
  margin: 12px 20px;
}
#basepval,
#expremival {
  -webkit-appearance: none;
}
#basepval::-webkit-outer-spin-button,
#basepval::-webkit-inner-spin-button,
#expremival::-webkit-outer-spin-button,
#expremival::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.era-table-container {
  border: 1px solid #e2e8ed;
  margin: 0px 24px 0px 24px;
}
.era-bottom-box {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.era-top-text {
  font-size: 28px;
  font-weight: 500;
  text-align: left;
}
.era-table-top-part {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  width: 100%;
}
.Era-upload-container {
  padding: 24px 24px 0px 24px;
}
.era-file-upload-box {
  border: none;
  background-color: #f5f8ff;
}
.era-upload-icon svg {
  height: 24px !important;
  width: 24px !important;
}
#era-list-column .MuiTableRow-root {
  border-bottom: "none !important";
}

.era-check-list-modal .modal-content {
  width: 660px !important;
}

/* ----------------------By Jeyabalaji ---------------------------- */
.prob-top-layer1 {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.bg-back-ins-claim {
  background-color: #fcfcfc;
  padding-top: 20px;
  /* padding-bottom: 20px; */
  margin-top: -5px !important;
}
.bg-back-ins-claim2 {
  background-color: #fcfcfc;
  padding-top: 20px;
  padding-bottom: 20px;
  /* margin-top: -5px !important; */
}
.bg-back-for-pat-bal {
  background-color: #fcfcfc;
  padding-top: 12px;
  padding-bottom: 12px;
}
.for-fee-sch-head {
  margin-top: 4px !important;
  margin-left: 4px !important;
}

.m-b-12-fee-sch {
  margin-bottom: 12px !important;
  margin-top: 20px !important;
  margin-left: 4px !important;
}
.m-b-12-for-icd9 {
  margin-bottom: 12px !important;
  margin-top: 12px !important;
}
.icd9-schedule-csv {
  height: 38px;
  margin-left: 20px;
}

.for-pat-pay-wi-bal {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}
.for-pat-pay-wi-pay {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}
.for-all-pat-stat5 {
  margin-top: 16px !important;
  margin-bottom: 13px !important;
  padding-top: 0px !important;
  margin-left: 15px !important;
}
.for-all-pat-stat2 {
  margin-top: 16px !important;
  margin-bottom: 13px !important;
  padding-top: 0px !important;
  margin-left: 15px !important;
  width: 300px !important;
  text-align: center;
}
.for-all-pat-stat3 {
  margin-top: 16px !important;
  margin-bottom: 13px !important;
  padding-top: 0px !important;
  margin-left: 15px !important;
  width: 75px !important;
}
.for-all-pat-stat4 {
  margin-top: 16px !important;
  margin-bottom: 13px !important;
  padding-top: 0px !important;
  margin-left: 15px !important;
  width: 75px !important;
}
.pat-stst-all-pat-th-row {
  padding-left: 6px !important;
}

.for-all-pat-stat1 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
  padding-top: 0px !important;
  margin-left: 4px !important;
  width: 200px !important;
}
.for-all-pat-stat1 .input {
  width: 100% !important;
}
.prob-tab-lay-pat-pay {
  margin-left: 4px !important;
  margin-top: 4px !important;
}
.pat-search-for-pat-bal {
  margin-left: 0px !important;
  margin-top: 12px;
  margin-bottom: 18px;
}
.pat-search-for-pat-bal .ant-input {
  border-radius: 6px !important;
}
.appt-block-main1 .ant-tabs-nav-wrap {
  margin-left: 4px !important;
}

.for-pat-bat-led-res {
  width: 25% !important;
  max-width: 100% !important;
  text-align: left;
}
.for-pat-bat-led-res .input {
  width: 100% !important;
}
.insurance_claim_form_1 {
  width: 15% !important;
}
.for-icd-9-srch-box-res {
  width: 20% !important;
}
.for-icd-9-srch-box-res .input {
  width: 100% !important;
}
/*Update By dhilip On 08-02-2022 start */
.for-icd-9-srch-box-res-but {
  width: 6%;
}
/*Update By dhilip On 08-02-2022 end */
.for-fee-sch-cpt {
  width: 20% !important;
}

/*Update By dhilip On 08-02-2022 start */
.for-fee-sch-cpt-but {
  width: 6%;
}
/*Update By dhilip On 08-02-2022 end */
.for-fee-sch-cpt .input {
  width: 100% !important;
}
.vo_1_1 {
  width: 20% !important;
}
.vo_1_1 .input {
  /* max-width: 0px !important; */
  width: 100% !important;
}
.insurance_claim_form_2 {
  width: 16% !important;
}

.ins-claim-report {
  margin-left: 4px !important;
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}
.insurance-claim-head th {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 24px;
  padding-right: 0px;
}
.insurance-claim-head td {
  padding-left: 24px;
  padding-right: 0px;
}
/* ----------------------By Jeyabalaji ---------------------------- */
.appoint_report_card {
  margin-top: 20px !important;
  margin-left: -6px !important  ;
}
.appoint_report_card .card {
  margin-top: 1% !important;
  margin-left: 2% !important;
  margin-right: 2% !important;
  margin-bottom: 1% !important;
}
.appoint_report_head {
  color: var(--button-background-color) !important ;
  font-size: 18px;
  font-weight: 500;
  float: left !important;
}
.appoint_report_card .card-header {
  color: var(--button-background-color);
  font-size: 18px;
  font-weight: 500;
  float: left !important;
  background-color: #fff;
  border-bottom: none !important;
}
.all-flex-center-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 3px 4px 1px;
}
.bill-card {
  margin-top: 1% !important;
  margin-left: 2% !important;
  margin-right: 2% !important;
  margin-bottom: 1% !important;
}

/* Update By Vijay */
.appoint_report_card {
  margin-top: 20px !important;
  margin-left: -6px !important  ;
}
.appoint_report_card .card {
  margin-top: 1% !important;
  margin-left: 2% !important;
  margin-right: 2% !important;
  margin-bottom: 1% !important;
}
.appoint_report_head {
  color: var(--button-background-color);
  font-size: 18px;
  font-weight: 500;
  float: left !important;
}
.appoint_report_card .card-header {
  color: var(--button-background-color);
  font-size: 18px;
  font-weight: 500;
  float: left !important;
  background-color: #fff;
  border-bottom: none !important;
}
.all-flex-center-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 3px 4px 1px;
}
.bill-card {
  margin-top: 1% !important;
  margin-left: 2% !important;
  margin-right: 2% !important;
  margin-bottom: 1% !important;
}

/* Update By Vijay  */

/* update by dhilip start*/
.tab-fee-cpt {
  min-width: 1100px;
}
/* update by dhilip end*/

/* update by vijay On 09-02-2022 Start */
/* jeyabalaji */
.Pat_statement_table {
  border: 1px solid #e2e8ed;
  box-sizing: border-box;
  border-radius: 6px;
  margin: 20px !important;
  /* padding-bottom: 56px !important; */
}
/* jeyabalaji */

.Tabs_Tabpane_1 .ant-tab.nav {
  margin-bottom: 20px !important;
}

.Tabs_Tabpane_1 .ant-tabs-tab {
  font-family: var(--font-work-sans);
  font-size: 14px !important;
  line-height: 17px !important;
  color: #718096;
}

.Tabs_Tabpane_1 .ant-tabs-tab-active .ant-tabs-tab-btn,
.Tabs_Tabpane_1 .ant-tabs-tab .ant-tabs-tab-active {
  color: var(--button-background-color) !important;
}
.Pat_statement_table_sublist {
  font-family: var(--font-work-sans);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: var(--button-background-color);
  text-align: left;
  margin: 20px 20px 20px 20px;
  /* border: 1px solid black; */
}
.Tab_content_Table_Body td {
  font-family: var(--font-work-sans) !important;
}
.Amount_space {
  font-family: var(--font-work-sans);
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #2d3748;
  padding: 0px 16px 0px 16px;
}
/* update by vijay On 09-02-2022 End  */
/* jeyabalaji */
.Tab_Filter_Background .ui.search .prompt {
  background-color: #fff !important;
}
.Tab_Filter_Background ::placeholder {
  font-family: var(--font-work-sans);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #242222;
}
.Tab_Filter_Background input {
  height: 32px !important;
}
.for-fee-sch-cpt ::placeholder {
  font-family: var(--font-work-sans);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #242222;
}
.for-icd-9-srch-box-res ::placeholder {
  font-family: var(--font-work-sans);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #242222;
}
.for-pat-stmt-tab-head tr:last-child {
  border-bottom: none !important;
}
.for-fee-schle-tble-head tr:last-child {
  border-bottom: none !important;
}
/* jeyabalaji */
/*--------------------------------- update cheran start 14.02.22-------------------------------------*/
.backgr-chng {
  background-color: #f7f7f7 !important;
}
.for-marg-nil .ant-input {
  height: 34px !important;
}
.backgr-chng1 {
  background-color: #ffff !important;
}

.table-boder-pad_down1 {
  border: 1px solid rgb(226, 232, 237);
  margin: 0px 20px 66.5px;
  padding: 0px 0 0px;
  border-radius: 6px;
}
.table-boder-pad_down2 {
  border: 1px solid rgb(226, 232, 237);
  margin: 20px 20px 20px 20px;
  padding: 0px 0 0px;
  border-radius: 6px;
}
.pat-pay-pad {
  padding-bottom: 0px !important;
}
.for-billpat-srch-pad {
  padding-top: 8px;
  padding-bottom: 4px;
  padding-left: 1px;
}
.for-pad-ledger {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.check-spn-alg {
  margin-bottom: 4px !important;
}
.form-control-icon {
  padding-right: 10px !important;
}
.placehld-ht {
  height: 34px !important;
}
.for-ledg-padnil {
  padding: 0px;
  margin-bottom: 14px;
}
.for-margtop-bot {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.m-t-20 .ui.icon.input > input {
  background-color: #ffff !important;
  height: 34px !important;
}
.for-marg-nil .ui.icon.input > input {
  background-color: #ffff !important;
  height: 34px !important;
}
.pad-down-fee1 {
  padding-bottom: 4px !important;
}
.m-t-20 .ui.icon.input > input:hover,
.m-t-20 .ui.icon.input > input:focus {
  box-shadow: none;
  border: 1px solid #e2e8ed;
}
.btn-fnt-wgt {
  font-weight: 600 !important;
}
.backgr-chng1 .ui.icon.input > input:hover,
.backgr-chng1 .ui.icon.input > input:focus {
  box-shadow: none;
  border: 1px solid #e2e8ed;
}
.eob_tab_date_picker .ant-picker {
  height: 34px !important;
}
.eob_tab_date_picker .ant-picker:hover {
  box-shadow: none;
  border: 1px solid #e2e8ed;
}
.ant-picker-focused {
  box-shadow: none;
  border: 1px solid #e2e8ed;
}
.form-control:focus {
  box-shadow: none;
  border: 1px solid #e2e8ed;
}
.btn-hover :focus,
.btn-hover:hover,
.btn-hover:active {
  box-shadow: none;
  border: 1px solid #e2e8ed;
  border-color: var(--button-background-color);
}
.for-marg-bot {
  margin-bottom: 20px !important;
}
.l-marg-nil {
  margin-left: 0 !important;
}

.pad-down {
  padding-top: 5px;
}
.btn-center-align {
  padding-top: 3px;
}
.backgr-chng1 .ant-input:hover {
  border: 1px solid #dbdbdb !important;
}
.for-marg-nil {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

#select-btn-hover:focus,
#select-btn-hover:active {
  box-shadow: none;
  border: 1px solid #e2e8ed;
}
#search-btn-hover:focus,
#search-btn-hover:active {
  box-shadow: none;
  border: 1px solid #e2e8ed;
}
.pad-down > div:nth-child(2) > div > div > div {
  box-shadow: none;
  border: 1px solid #e2e8ed;
  outline: none;
}
/* .date-btn-hover .ant-picker-input.ant-picker-input-active > input{
  color:#242222;
/* } */
/* .sec-row-ins.pad-down > div:nth-child(2) > div > div > div > div.ant-picker-input.ant-picker-input-active > input{
  color:#242222 !important;
} 
.sec-row-ins.pad-down > div:nth-child(2) > div > div > div > div:nth-child(3) > input{
  color:#242222 !important;
} */
.for-span-pat {
  border-radius: 4px;
  display: grid;
  margin-left: 25px;
  place-items: center;
  padding: 10px 20px 10px 20px;
  margin-top: 0px;
}
.border-bot-nil {
  border-top: none !important;
}
.height-adj {
  height: auto;
  margin-bottom: -53px;
}

#for-fee-marg {
  margin: 0px -2px -2px -6px !important;
}
.for-sumtble-pad {
  padding-bottom: 16px;
  padding-left: 19px;
}
.for-sumtble-padl {
  padding-top: 9px;
}
.for-fee-padbt {
  padding-bottom: 4px;
}
.for-sumtble-padt {
  padding-top: 10px;
}
.backgr-chng .ant-picker:hover {
  box-shadow: none;
  border: 1px solid #e2e8ed;
}
.backgr-chng .ant-input:hover {
  box-shadow: none;
  border: 1px solid #e2e8ed;
}
.backgr-chng .ui.icon.input > input:hover,
.backgr-chng .ui.icon.input > input:focus {
  box-shadow: none;
  border: 1px solid #e2e8ed;
}
.sum-credit-pad {
  padding-top: 10px !important;
  margin-bottom: 0px !important;
}
.btn-mrg-l {
  margin-left: 3% !important;
}
.pad-bott-th {
  padding-bottom: 20px !important;
}
.rep-bck-chn {
  background-color: #f4f4f4 !important;
  color: #000000 !important;
  border-bottom: 1px solid #cbd5e0 !important;
}
.font-color-rep {
  color: #000000 !important;
}
.marg-top-l {
  margin-top: 20px !important;
  margin-left: 20px !important;
}

/*-end--*/
/*-----update by cheran 22-3-22--------*/
.DisF {
  display: flex !important;
  width: max-content !important;
  /* updated by saravanan 28.3.22 5:50 */
  padding: 2px !important;
}
.t-padLeft {
  padding-left: 20px !important;
}
/* update dby saravanana 23.3.22 start */
.bil-pat-bal-search .results.transition.visible {
  overflow: auto !important;
  max-height: 200px !important;
}
/* update dby saravanana 23.3.22 start */
.load_more_alt {
  padding-top: 25px !important;
  padding-bottom: 0px !important;
}

.allpat_load_more_alt {
  padding-top: 10px !important;
  padding-bottom: 0px !important;
}

div.MuiPaper-root.MuiTableContainer-root.for-marg-nil.MuiPaper-elevation1.MuiPaper-rounded
  > table
  > tbody
  > tr:last-child {
  border-bottom: none !important;
}

.table-for-template2 tr:last-child {
  border-bottom: none !important;
}
.loadmore-patBalance {
  margin-top: -35px;
  margin-bottom: 10px;
}
.for-pat-ledger {
  padding-top: 20px !important;
  padding-bottom: 1px !important;
}
.patBalanceTable tr:last-child {
  border-bottom: none !important;
}

#patBalTableContainer {
  margin-top: 0px !important;
}

.ad-fe-sch-mt10 {
  margin-top: 10px !important;
}
.ad-fee-sch-mr10 {
  margin-right: 10px !important;
}
.ad-fee-sch-search .results.transition.visible {
  overflow: auto !important;
  max-height: 225px !important;
}
.ad-fee-sch-search .ui.icon.input {
  width: 100%;
  height: 32px;
}
.ad-fee-sch-search.ui.search {
  width: 358px;
}
.fee-sch-btn-hov.btn.btn-danger {
  color: #d9534f;
  border: 1px solid #d9534f;
  background: #f7f7f7;
}
.fee-sch-btn-hov.btn.btn-danger:hover {
  background: #d9534f;
  color: #f7f7f7;
}
.fee-sch-icd9-pd {
  padding-bottom: 0px;
}
.fee-sch-icdtable-pd {
  padding-left: 20px;
  padding-bottom: 0px;
}
/*---------------update by cheran-----------26-4-22--*/
.bill-mgb-20 {
  margin-bottom: 20px !important;
}
.bill-lmb {
  padding-top: 0px !important;
}
/*---------------update by cheran-----------26-4-22--*/

.paid_full {
  color: #57bf7f;

  font-weight: 500;
  text-align: center;
}

.appt-head tbody > tr:last-child {
  border-bottom: none !important;
}
.radio-pat-pay {
  height: 15px !important;
  width: 15px;
}
.radio-pat-pay:focus {
  border-color: #fff !important;
  box-shadow: none !important;
}

.loading-more.pad-set {
  padding-top: 0px;
}
.right-block .appt-block-main {
  padding-bottom: 10px;
}
.appt-block-main {
  margin-bottom: 10px;
}

.Upload_Era_container {
  margin: 0px 20px 0px 20px;
  border: 1px solid rgb(226, 232, 237);
  border-radius: 6px;
}

.Upload_Era_container .ant-upload-drag-container_1 input[type="file"] {
  font-size: 100px;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer !important;
  height: 18px;
}

.Upload_Era_container .MuiPaper-root {
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}
.Hide_Last_Child tr:last-child {
  border-bottom: none !important;
}
.pat-list-thead tr {
  border-bottom: none !important;
}
.eramsg .modal-dialog {
  max-width: 600px !important;
  width: 600px !important;
}
.erapad {
  border-radius: 6px;
  text-align: center !important;
  padding: 20px;
}
.era-bottom-box .process_era_check {
  margin-top: 0px !important;
}
.era_cheque tr:last-child {
  border-bottom: none !important;
}
.table-data-row .Cheque_No {
  margin: 0 !important;
  margin-top: 0 !important;
}
.era_success .modal-dialog {
  width: 470px !important;
}
.eob-mr-b-15 {
  margin-bottom: 15px !important;
}
.eob-flec-jus-bet {
  justify-content: space-between;
}
.eob-imp-brdr-clr {
  border: 1px solid #cbd5e0 !important;
}
.eob-src-w240 {
  width: 240px;
}
.eob-mb-10-imp {
  margin-bottom: 0px !important;
}
.eob-ins-pag-pad {
  padding-left: 35px;
  padding-right: 35px;
}
.eob-mr-b-15 {
  margin-bottom: 15px !important;
}
.eob-flec-jus-bet {
  justify-content: space-between;
}
.eob-imp-brdr-clr {
  border: 1px solid #cbd5e0 !important;
}
.eob-pd-10-imp {
  padding-bottom: 10px !important ;
}
.eob-pd-5-imp {
  padding-bottom: 5px !important;
}
/*Ahamed_Changes_07-05-22*/
.patient-payment .appt-block-main {
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}
.patient-payment .ant-tabs-nav-wrap {
  margin-left: 0px !important;
}
.patient-payment .for-billpat-srch-pad {
  padding-left: 0px;
}
.patient-payment .full-pay {
  margin-top: 8px;
  padding-left: 0px;
  padding-right: 0px;
}
.patient-payment .full-pay .MuiTableCell-body {
  color: #636d73 !important;
}
.patient-payment .full-pay td:first-child {
  font-weight: 500;
}
.patient-payment .full-pay th {
  color: #000 !important;
}
.full-pay div.mr-0 {
  border-top: 1px solid #cbd5e0 !important;
  margin-top: -7px;
}
/*Ahamed_Changes_07-05-22*/

/* Vanitha 09-05-22 */

.pat_stmt_alt input {
  height: 32px !important;
}

.chek_labl_patstmt_alt .form-group label {
  margin-top: -5px !important;
}

.chek_labl_patstmt_alt .form-group {
  margin-bottom: -5px !important;
}

.chek_labl_patstmt_alt .form-group span {
  margin-top: -5px !important;
}

.pat_stmt_all_alt .form-group {
  margin-bottom: -7px !important;
}

.pat_stmt_all_alt .form-group label {
  margin-top: -5px !important;
}

.pat_stmt_all_alt .form-group span {
  margin-top: -5px !important;
}

.pat_Stmt_tab_bot_alt {
  padding-bottom: 10px !important;
}
.eob-ad-ic {
  margin-left: -3px;
  margin-right: 5px;
}
/* Vanitha 09-05-22 */
.ERA_Process_ .close {
  margin-top: 8px !important;
}

.era-list-tbl .MuiTableContainer-root {
  margin-top: 0 !important;
}
.eob_tab_date_picker .anticon.anticon-swap-right {
  margin-right: 18px;
}
.eob-mk-mrgt-nill.appt-block-main {
  margin-top: 0px !important;
}
.ad-fsch-all-lbl .form-label {
  margin-bottom: 11.5px !important;
}
.fee-sch-crd .card-header {
  padding: 20px !important;
}
.fee-sch-ad-nw .modal-header button.close {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px !important;
  width: 26px !important;
}
.fee-sch-ad-nw .modal-header button.close span:not(.sr-only) {
  height: 16px !important;
  width: 16px !important;
  margin-top: -5px;
  margin-left: -1px;
}
.mk-all-pdng-20.modal-header,
.mk-all-pdng-20.modal-footer,
.mk-all-pdng-20.modal-body {
  padding: 20px !important;
}
.fcsh-m-p-nill {
  margin: 0px 16px 0px 0px !important;
}
.ad-fsch-all-lbl .form-group {
  margin-bottom: 0px !important;
}
.ad-fsch-all-lbl .form-group:not(.fc-sch-not-me) {
  margin-top: 10px !important ;
}
.m-fd-fhg-mar.form-row {
  margin-top: -7px !important;
}
.load-more-pad-t.loading-more {
  padding-top: 40px !important;
}
.eob-padl-34.pay-head {
  padding-left: 34px !important;
}
.eob-ins-pag-pad select,
.eob-sec-row-box select {
  height: 32px !important;
}
.p-l-20-impnt {
  padding-left: 19px !important;
}
.eob-sec-row-box {
  border: 1px solid #cbd5e0;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  margin: 0px 20px;
}
.eob-sec-row-box select.eob-dropdwn-slctr {
  width: 200px !important;
}
.eob-sec-row-box select.eob-dropdwn-slctr2 {
  width: 182.5px !important;
}
.eob-mrg-l-r-20 {
  margin-left: 10px;
  margin-right: 19px;
}
.eob-mrg-12-rgt {
  margin-right: 21px;
}
.eob-mrg-r-105 {
  margin-right: 10.5px;
}
.eob-last-marg-8 {
  margin-left: 8px;
}
.eob-sml-mrgt2 {
  margin-top: 2px;
}

/* Vanitha 10-05-22 */

.pat_all_stmt_botpad_alt {
  margin-bottom: 0px !important;
}

.pat_stmt_inp_wid_alt {
  width: 12.5% !important;
}
.pat_stmt_inp_wid_alt input::placeholder {
  font-size: 12.5px !important;
  color: rgba(0, 0, 0, 0.85) !important;
  font-weight: 500;
  font-family: var(--font-work-sans) !important;
}

.ant_chk_stbal1 {
  margin-right: 7px !important;
}
/* Vanitha 10-05-22 */

/* Vanitha 11-05-22 */

.pat_stmt_whole_filt_alt {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 19px !important;
}

/* Enable horizontal scrolling for small screens */
.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on touch devices */
}

/* Hide less important columns on small screens */
@media (max-width: 768px) {
  .act_pat_stmt_th .TableHeadCell:nth-child(4), /* Facility */
  .act_pat_stmt_th .TableHeadCell:nth-child(5), /* Patient Balance */
  .act_pat_stmt_th .TableHeadCell:nth-child(6), /* Insurance Balance */
  .act_pat_stmt_tbody .TableBodyCell:nth-child(4), /* Facility */
  .act_pat_stmt_tbody .TableBodyCell:nth-child(5), /* Patient Balance */
  .act_pat_stmt_tbody .TableBodyCell:nth-child(6) /* Insurance Balance */ {
    display: none;
  }
}

/* Stack rows for very small screens */
@media (max-width: 576px) {
  .table-data-row {
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid #cbd5e0;
    padding: 10px;
  }

  .TableBodyCell {
    text-align: left !important;
    padding: 5px 0 !important;
  }

  .TableBodyCell::before {
    content: attr(data-label);
    font-weight: bold;
    display: inline-block;
    width: 100px;
    margin-right: 10px;
  }
}

#billing_pat_stmt_pat_srch-b::placeholder {
  color: red !important; /* Change to your preferred color */
  opacity: 1; /* Ensure visibility */
}

.pat_stmt_totfilt_alt {
  margin-bottom: 20px !important;
}

.pat_Stmt_secinp_alt {
  margin-right: 20px !important;
}

.pat_stmt_and_alt {
  margin-right: 12px !important;
  padding: 0px !important;
}

.pat_stmt_ins_alt1 input {
  margin-right: 16px !important;
}

.pat_stmt_frstins_alt {
  margin-right: 12px !important;
}

/* Vanitha 11-05-22 */
/*--------------------update by cheran -----------------------*/
.for_dot_act_bg1 {
  position: relative !important;
  border-radius: 50% !important;
  height: 16px !important;
  width: 16px !important;
  margin-right: 5px !important;
}

.dot-act_inactive_alt {
  border-radius: 50%;
  height: 8px;
  width: 8px;
  top: 4px !important;
  left: 4px !important;
  position: absolute;
}
.t-unq #dropdown-basic {
  background: none !important;
}
/*-----------------update by Cheran End -----------------------*/
/*Ahamed_Changes_16_05_22*/
.proc-order-det .results.transition {
  width: 406px;
  max-height: 250px;
  position: relative;
}
/*Ahamed_Changes_16_05_22*/
/* Vanitha 17-05-22 */

.act_inactive_alt1 {
  top: 6px !important;
}

/* Vanitha 17-05-22 */
/* Vanitha 17-06-22 */

.bor_rad_alteration {
  border-radius: 6px !important;
  overflow-x: hidden;
}

/* Vanitha 17-06-22 */

.new-table-row-head > tr {
  background-color: #f1f5f8 !important;
}
.new-tab-head-Denial .new-table-row-head > tr > th {
  color: #777984 !important;
  font-family: var(--font-work-sans) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
}
.denial-total-row > td {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  font-family: var(--font-work-sans) !important;
}
.Denial_data_map > td {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 17px !important;
  font-family: var(--font-work-sans) !important;
  padding: 5px 5px 5px 5px !important;
}
.arrow-show-denial .ant-select-selector {
  width: 160px !important;
}
.arrow-show-denial .ant-select-arrow {
  width: 10px !important;
}
.arrow-show-denial .ant-select-selection-item {
  text-align: left !important;
}
.checkbox-denial-analysis .ant-checkbox-wrapper {
  margin-top: 0px !important;
  margin-left: 0px !important;
  margin-right: 19px !important;
}
.date-pick-denial .ant-picker:hover,
.checkbox-denial-analysis .ant-checkbox-wrapper .ant-checkbox:hover {
  border-color: #cbd5e0 !important;
}
.arrow-show-denial .ant-select-selector:hover {
  border-color: #cbd5e0 !important;
}
.checkbox-denial-analysis
  .ant-checkbox-wrapper
  .ant-checkbox
  .ant-checkbox-input:focus,
.checkbox-denial-analysis
  .ant-checkbox-wrapper
  .ant-checkbox
  .ant-checkbox-inner {
  border-color: #cbd5e0 !important;
}
.checkbox-denial-analysis
  .ant-checkbox-wrapper
  .ant-checkbox
  .ant-checkbox-inner
  ::after {
  transform: none !important;
  transition: none !important;
}
.date-pick-denial .ant-picker-input > input,
.date-pick-denial .ant-picker-input > input::placeholder {
  color: #718096 !important;
}

.denial-analysis-tooltip .recharts-default-tooltip {
  height: max-content !important;
}

.button-strike ::before {
  content: "";
  width: 84px;
  height: 1px;

  border: 1px solid #232323;
  transition: 0.2s;
  margin-top: 2px;
  margin-left: -6px;
  display: block;
}
.button-strike-1 ::before {
  content: "";
  width: 98px;
  height: 1px;

  border: 1px solid #232323;
  transition: 0.2s;
  margin-top: 2px;
  margin-left: -6px;
  display: block;
}
.button-strike-2 ::before {
  content: "";
  width: 85px;
  height: 1px;

  border: 1px solid #232323;
  transition: 0.2s;
  margin-top: 2px;
  margin-left: -6px;
  display: block;
}
.denial-analysis-tooltip .recharts-tooltip-label {
  margin: 10px !important;
}
.arrow-show-denial .ant-select-selector {
  border-color: #cbd5e0 !important;
  box-shadow: none !important;
}

.fee_schedult_inalt .input .prompt {
  margin-right: 0px !important;
}
.bil_invoice #fullscreen-popup .modal-content {
  height: auto !important;
}
/*Ahamed_Thaha 14-02-2023*/

#pat_stmnt_table .Fiter_Submit_Btn {
  margin-left: 0px;
  height: 38px;
  width: 100px;
  font-weight: 500;
  font-size: 14px;
  background-color: var(--button-background-color);
}

/* #pat_stmnt_table .alle_med_frst_col_alt {
  margin-left: -1px;
} */

/* Billing Changes */
#pat_stmnt_table tr .pat_text,
#pat_all_stmnt_table tr .pat_text {
  padding-left: 20px !important;
}
/* Billing Changes */

#pat_all_stmnt_table #billing-pat-all_stmt_srch_btn-b {
  margin-left: 0px;
  height: 38px;
  width: 100px;
  font-weight: 600;
  padding-top: 2px;
}

#fee_sched_table .all-tbl-text-main > div {
  /* width: 220px; */
  margin-left: 4px;
}
#fee_sched_table .table-for-template1,
#fee_sched_icd9 .table-for-template1,
#fee_sched_icd10 .table-for-template1 {
  margin-top: -10px;
}
#fee_sched_table .wid-12 {
  width: 12%;
}
.insurance-claim-head .f-w-500 {
  font-weight: 500;
}
.ins-clm-table .bg_ecf7f0 {
  background-color: #ecf7f0;
}
.ins-clm-table .bg_57bf7f {
  background-color: #57bf7f;
}
.ins-clm-table .bg_E6CCFF {
  background-color: #e6ccff;
}
.ins-clm-table .bg_FFE0E0 {
  background-color: #ffe0e0;
}
.ins-clm-table .bg_ff6666 {
  background-color: #ff6666;
}
.ins-clm-table .bg_F2CCFF {
  background-color: #f2ccff;
}
.ins-clm-table .bg_FFEBCC {
  background-color: #ffebcc;
}
.ins-clm-table .bg_F2FFCC {
  background-color: #f2ffcc;
}
.ins-clm-table .bg_CCFFD9 {
  background-color: #ccffd9;
}
.ins-clm-table .bg_CCCCFF {
  background-color: #ccccff;
}
.ins-clm-table .bg_FFCCE6 {
  background-color: #ffcce6;
}
.ins-clm-table .bg_8000ff {
  background-color: #8000ff;
}
.ins-clm-table .bg_bf00ff {
  background-color: #bf00ff;
}
.ins-clm-table .bg_ff9a00 {
  background-color: #ff9a00;
}
.ins-clm-table .bg_bfff00 {
  background-color: #bfff00;
}
.ins-clm-table .bg_00ff40 {
  background-color: #00ff40;
}
.ins-clm-table .bg_0000ff {
  background-color: #0000ff;
}
.ins-clm-table .bg_ff0080 {
  background-color: #ff0080;
}
.ins-clm-table .clr_57bf7f {
  color: #57bf7f;
}
.ins-clm-table .clr_8000ff {
  color: #8000ff;
}
.ins-clm-table .clr_ff6666 {
  color: #ff6666;
}
.ins-clm-table .clr_bf00ff {
  color: #bf00ff;
}
.ins-clm-table .clr_ff9a00 {
  color: #ff9a00;
}
.ins-clm-table .clr_bfff00 {
  color: #bfff00;
}
.ins-clm-table .clr_00ff40 {
  color: #00ff40;
}
.ins-clm-table .clr_0000ff {
  color: #0000ff;
}
.ins-clm-table .clr_ff0080 {
  color: #ff0080;
}
.ins-clm-table .clr_2E2E2E {
  color: #2e2e2e;
}
.ins-clm-table .ovr-xflow {
  overflow-x: unset;
}
.ins-clm-table .stat_ins {
  min-width: 145px;
  max-width: 250px;
}
.ins-clm-table .clm_ins {
  width: 130px;
}
.ins-clm-table .bg_wh-cl_blk {
  background-color: white;
  color: black;
}
.ins-clm-modal .clear-validate {
  margin-right: 12px;
  height: 32px;
  border: 1px solid #0062cc;
  background-color: white;
  color: #0062cc;
}
.ins-clm-modal .valid-only {
  margin-right: 12px;
  height: 32px;
  border: 1px solid #2e2e2e;
  background-color: white;
  color: #2e2e2e;
}
.ins-clm-modal .ins-clm-cntinu {
  margin-right: 12px;
  height: 32px;
}
.ins-clm-modal .val-mod-txt.m-b-0 {
  margin-bottom: 0px !important;
}
.for-fl-pad-btm.set-mrgn {
  margin-bottom: -19px;
}
.ins-clm.table .m-l-40 {
  margin-left: 40px;
}
.pat-w-b .srch_pat {
  padding-left: 0px;
  margin-left: 1.8%;
}
.brdr_btm_radius-6 {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.pat-w-b .mrgn_algn {
  margin-left: -9px;
}
.pat-w-b .btn-pay {
  background-color: var(--button-background-color);
  width: 70px;
}
.pat-w-b .pid-lft-mrgn {
  margin-left: 6px;
}
.pat_pay-tbl .btn-prmary {
  width: 70px;
}
.pat_pay-tbl .for-pat-pay-wi-pay {
  border-radius: 6px;
}
.pat_pay-tbl .for-billpat-srch-pad {
  margin-top: 12px;
  margin-bottom: 12px;
}
.pat_pay-tbl .pat-pay-pad {
  display: block;
  margin-top: 1%;
  padding-top: 0;
  padding-bottom: 1.5%;
}
.pay_pay-tbl .btn-pay {
  width: 70px;
  border: 1px solid #75d4d0;
}
.pat_ledgr .for-pat-ledger {
  display: flex;
  border-radius: 6px;
}
.eob-pd-5-imp .lab-res-spin {
  height: 200px;
}
.era-seperator-container .fl-mgr-not {
  margin-left: 0;
}
.era-seperator-container .m-t-30 {
  margin-top: 30px;
}
.era-seperator-container .p-l-25.f-s-16 {
  padding-right: 25px;
}
.era-seperator-container .lab-res-spin {
  height: 250px;
}
.era-seperator-container .Dpd-pad.m-l-0 {
  margin-left: 0px;
}
.era-check-list-modal .p-t-16.p-b-16 {
  padding-left: 0px;
  padding-right: 0px;
}
.era-table-top-part > .f-w-500.m-l-20 {
  margin-left: 20px;
  font-size: 18px;
}
.era-list-tbl .clr_E91B1B {
  color: #e91b1b;
}
.era-check-list-modal .p-t-16.p-b-16 .w-100 {
  display: flex;
  justify-content: end;
  padding-right: 17px;
}
.era-check-list-modal .p-t-16.p-b-16 .f-w-500.srx-iconlf {
  border: 1px solid #cbd5e0;
}
.era-table-top-part .p-l-20.clr_2C7BE5 {
  color: var(--button-background-color);
}
.era-check-list-modal .bdy_modal_era {
  padding: 0px;
  height: 550px;
  overflow-x: hidden;
}
.era_success .icons-bs.m-r-10 {
  padding-right: 0px;
}
.era-bottom-box .pros_era {
  margin-right: 30px;
  height: 32px !important;
}
.erapad .icons-bs {
  margin-bottom: 4px;
  margin-right: 5px;
}
.era_success .alert-success {
  height: 90px;
}
.era-upload-icon.m-t-22 {
  margin-top: 22px !important;
}
.patient-payment .ant-tabs-tab {
  padding-top: 0px !important ;
}
.pat_Stmt_tab_bot_alt .ant-tabs-tab {
  padding-top: 0px !important;
}
.fee-schedule-head .ant-tabs-tab {
  padding-top: 0px !important;
}
.eob-mk-mrgt-nill .ant-tabs {
  padding-top: 10px !important;
}
.height-fit-con {
  height: fit-content;
}
/*Ahamed_Thaha 14-02-2023*/
.searchFontWeight {
  font-weight: 700;
}

/*new_files*/
.for_autoComplete_bi {
  padding-left: 20px;
  margin-right: 20px;
  display: flex;
  position: relative;
}
.for_autoComplete_bi svg {
  z-index: 1;
  margin: 8px 0px 0px 10px;
  width: 18px;
  height: 18px;
}
.for_autoComplete_bi .ant-select-auto-complete {
  position: absolute;
}
.for_autoComplete_bi .ant-select-selector {
  padding-left: 40px !important;
  border-radius: 6px !important;
}
.for_autoComplete_bi input {
  border: none !important;
}
.for_autoComplete_bi .ant-select-selection-search {
  padding-left: 28px;
}
.for_autoComplete_bi .ant-select-selection-placeholder {
  color: #000;
  padding-top: 1px;
}

.for_autoComplete_bi
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: none !important;
  box-shadow: none !important;
}

.pat_Stmt_tab_bot_alt .ant-tabs-tab {
  padding-top: 0px;
}

.pat_Stmt_tab_bot_alt .for_autoComplete_bi {
  padding-left: 0px !important;
}
.ant-select-dropdown-empty .ant-select-item-empty,
.ant-select-item-option .ant-select-item-option-content {
  color: #000;
  font-weight: 600;
}
/* #pat_stmnt_table .ant-checkbox-wrapper,
#pat_all_stmnt_table .ant-checkbox-wrapper {
  margin-left: 201px;
}
#pat_stmnt_table .form-group span,
#pat_all_stmnt_table .form-group span {
  margin-top: -3px !important;
}
.pat_pay-tbl #search-btn-hover:focus {
  border: none;
}
.pat_ledgr .for-span-pat {
  margin-left: 87px;
}
.fee-schedule-head .for_autoComplete_bi {
  padding-left: 0px !important;
  margin-right: 0px !important;
} */
.for_autoComplete_bi.card-tran-srch svg {
  margin-top: 1px;
}
.for_autoComplete_bi.card-tran-srch .ant-select-selector {
  height: 34px;
}
/*-----ramachandran--------*/
.for_autoComplete_bi_01 {
  margin-right: 20px;
  display: flex;
  position: relative;
  width: 175px;
}
.for_autoComplete_bi_01 svg {
  z-index: 1;
  margin: 8px 0px 0px 10px;
  width: 18px;
  height: 18px;
  opacity: 0.5;
}
.for_autoComplete_bi_01 .ant-select-auto-complete {
  position: absolute;
}
.for_autoComplete_bi_01 .ant-select-selector {
  padding-left: 40px !important;
  border-radius: 6px !important;
}
.for_autoComplete_bi_01 input {
  border: none !important;
}
.for_autoComplete_bi_01 .ant-select-selection-search {
  padding-left: 28px;
}
.for_autoComplete_bi_01 .ant-select-selection-placeholder {
  color: #000;
  padding-top: 1px;
}

.for_autoComplete_bi_01
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: none !important;
  box-shadow: none !important;
}

.pat_Stmt_tab_bot_alt .ant-tabs-tab {
  padding-top: 0px;
}

.pat_Stmt_tab_bot_alt .for_autoComplete_bi_01 {
  padding-left: 0px !important;
}
.ant-select-dropdown-empty .ant-select-item-empty,
.ant-select-item-option .ant-select-item-option-content {
  color: #000;
  font-weight: 600;
}
#pat_stmnt_table .ant-checkbox-wrapper,
#pat_all_stmnt_table .ant-checkbox-wrapper {
  margin-left: 201px;
}
#pat_stmnt_table .form-group span,
#pat_all_stmnt_table .form-group span {
  margin-top: -3px !important;
}
.pat_pay-tbl #search-btn-hover:focus {
  border: none;
}
.pat_ledgr .for-span-pat {
  margin-left: 87px;
}
.fee-schedule-head .for_autoComplete_bi_01 {
  padding-left: 0px !important;
  margin-right: 0px !important;
}
.for_autoComplete_bi_01.card-tran-srch svg {
  margin-top: 1px;
}
.for_autoComplete_bi_01.card-tran-srch .ant-select-selector {
  height: 34px;
}
/*-----ramachandran--------*/
.validate_clear_msg {
  word-wrap: break-word;
  padding: 5px;
}
.forredX12 {
  background-color: red;
}
/* nisha start */
.for_autoComplete_bi {
  display: flex;
  position: relative;
}

.for_autoComplete_bi svg {
  z-index: 1;
  margin: 8px 0px 0px 10px;
  width: 18px;
  height: 18px;
  opacity: 0.5;
}

.for_autoComplete_bi .ant-select-auto-complete {
  position: absolute;
}
.for_autoComplete_bi .ant-select-selector {
  padding-left: 40px !important;
  border-radius: 6px !important;
}
.for_autoComplete_bi input {
  border: none !important;
}
.for_autoComplete_bi .ant-select-selection-search {
  padding-left: 28px;
}
.for_autoComplete_bi .ant-select-selection-placeholder {
  color: #5a5959 !important;
  padding-top: 1px;
}

.for_autoComplete_bi
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: none !important;
  box-shadow: none !important;
}
.for_autoComplete_bi
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  max-width: 100%;
  height: 34px;
  padding: 0px 11px;
}
.for_autoComplete_bi .ant-select-dropdown-empty .ant-select-item-empty,
.ant-select-item-option .ant-select-item-option-content {
  color: #000 !important;
  font-weight: 600;
  /* border-bottom: 1px solid rgb(180, 180, 192); */
}
.for_autoComplete_bi .ant-select {
  font-size: 15px;
  font-weight: 500;
  font-family: var(--font-work-sans);
}
.ant-select-item-empty {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85) !important;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

/* nisha end*/

/* saranya table 10-04-23 */
.pat_pay_table {
  margin: 12px 0 11px 4px !important;
}
.pat_pay_sectab {
  margin: 4px 0 0px 0px;
}
/* saranya table 10-04-23 */

/* Vanitha */
.feeSchedule_action_alt {
  padding: 0px 20px 2px 0px !important;
}

.feeSchedule_patname {
  padding-left: 0px !important;
}

.pat_stmt_update_btn_alt {
  height: 32px !important;
  margin-left: 20px;
  width: 73px;
}

.loadMore_btn {
  padding-top: 20px !important;
}

.loadMore_bot_ {
  padding-bottom: 20px !important;
}

.load_more_feeschedule {
  padding-top: 9px !important;
}

.load_more_eobTable {
  padding-top: 20px !important;
  padding-bottom: 1px !important;
}

.load_more_eobInstbl {
  padding-bottom: 6px !important;
}

.ac_searchChanges {
  width: 100%;
}

.pat_stmt_ml_0 {
  margin-left: 0px !important;
}

.fee_schedule_upd_btn {
  width: 9% !important;
}

.fee_sched_header {
  padding-bottom: 10px !important;
}

.feeSchedule_cpt_search1 {
  padding-left: 0px !important;
  margin-left: 0px !important;
}

.act_pat_stmt_tbody td,
.act_pat_stmt_th th {
  padding: 8px 0 8px 5px !important;
}

.act_pat_name_pad {
  padding-left: 14px !important;
}

.fee_schedul_acti_alt {
  padding: 8px 16px 8px 6px !important;
}
/* Vanitha */

/* Ahamed */
.for_autoComplete_bi.add-fee-code {
  padding-left: 0px;
  width: 100%;
}

/* Ahamed */

/* nisha start */
.for_autoComplete_bi_CT {
  display: flex;
  position: relative;
}

.for_autoComplete_bi_CT svg {
  z-index: 1;
  margin: 8px 0px 0px 10px;
  width: 18px;
  height: 18px;
  opacity: 0.5;
}

.for_autoComplete_bi_CT .ant-select-auto-complete {
  position: absolute;
}
.for_autoComplete_bi_CT .ant-select-selector {
  padding-left: 40px !important;
  border-radius: 6px !important;
}
.for_autoComplete_bi_CT input {
  border: none !important;
}
.for_autoComplete_bi_CT .ant-select-selection-search {
  padding-left: 28px;
}
.for_autoComplete_bi_CT .ant-select-selection-placeholder {
  color: #5a5959 !important;
  padding-top: 1px;
}

.for_autoComplete_bi_CT
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: none !important;
  box-shadow: none !important;
}
.for_autoComplete_bi_CT
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  max-width: 100%;
  height: 34px;
  padding: 0px 11px;
}
.for_autoComplete_bi_CT .ant-select-dropdown-empty .ant-select-item-empty,
.ant-select-item-option .ant-select-item-option-content {
  color: #000 !important;
  font-weight: 600;
  /* border-bottom: 1px solid rgb(34 36 38 / 3%); */
  /* padding: 6px 24px 6px 16px; */
}
.ant-select-dropdown-empty .ant-select-item-empty,
.ant-select-item-option .ant-select-item-option-content {
  color: #000 !important;
  font-weight: 600;
  /* border-bottom: 1px solid rgb(34 36 38 / 3%); */
  /* padding: 6px 24px 6px 16px; */
}

.for_no_loading {
  display: none !important;
}
.ant-select-item {
  padding: 5px 14px !important;
  border-bottom: 1px solid rgb(34 36 38 / 3%);
}
.ant-select-selector {
  border-radius: 4px !important;
}
.ant-select-item-option-state {
  margin-top: 5px;
}

.for_autoComplete_bi_CT .ant-select {
  font-size: 14px;
  font-weight: 400;
  font-family: var(--font-work-sans);
}
.ant-select-item-empty_CT {
  position: relative;
  display: block;
  min-height: 32px !important;
  padding: 5px 12px !important;
  color: rgba(0, 0, 0, 0.85) !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 22px !important;
}

/* nisha end*/
.patient-payment .full-pay {
  margin-top: 16px;
  padding-left: 0px;
  padding-right: 0px;
  height: 87px;
}
/* saranya 03-03-23 */

/* patient payment serach changed bye nisha  */

.paitent-pay-search
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  width: 100%;
  height: 34px;
  padding: 0 11px;
}

/*  nisha  end  */

/* Vanitha 4-05-23 */

.feeschedule_btn button {
  font-weight: 500 !important;
}

.act_pat_stmt_th th:last-child {
  padding: 0px 28px 0px 6px !important;
}

.marginTop0 {
  margin-top: 0px !important;
}

.ins_claim_table th {
  padding: 0px 6px 0px 3px !important;
}

.ins_claim_table td {
  padding: 0px 6px 0px 3px !important;
}

.ins_claim_table th:first-child {
  padding-left: 20px !important;
}

.ins_claim_table td:first-child {
  padding-left: 20px !important;
}

.ins_claim_table th:last-child {
  padding: 0px 88px 0px 14px !important;
}
/* Vanitha 4-05-23 */
.ant-select-selector {
  border-radius: 4px !important;
}
.ant-select-item-option-state {
  margin-top: 5px;
}
.for_ndc_h_w_adj .ant-select {
  width: 100% !important;
}
.era-check-list-modal .modal-dialog {
  max-width: 600px !important;
}
.card-tran-srch .for_autoComplete_bi {
  z-index: 0;
}

.pat_bal_main_th th {
  color: #005fbe !important;
  font-weight: 400 !important;
}

/* vignesh css 26/09/2023 */

.save_active {
  /* background-color: #2c7be5 !important; */
  background-color: var(--button-background-color) !important;
  border-color: var(--button-background-color);
}
.save_active:active {
  /* background-color: rgba(44, 123, 229, 0.75) !important; */
  background-color: var(--button-background-color) !important;
  border: none !important;
}
.save_active:disabled {
  /* background-color: rgba(44, 123, 229, 0.5) !important; */
  background-color: var(--button-background-color) !important;
  border: none !important;
}

.close_active {
  border-radius: 4px;
  /* color: #2c7be5; */
  color: var(--button-background-color) !important;
  /* border: 1px solid #2c7be5; */
  border: 1px solid var(--button-background-color) !important;
  background: #fff;
}
.close_active:active {
  /* background-color: rgba(44, 123, 229, 0.1) !important; */
  background-color: var(--button-background-color) !important;
  /* border: 1px solid #2c7be5 !important; */
  border: 1px solid var(--button-background-color) !important;
  /* color: #2c7be5 !important; */
  color: var(--button-background-color) !important;
}
.close_active:disabled {
  /* color: rgba(44, 123, 229, 0.5) !important; */
  color: var(--button-background-color) !important;
  background-color: #fff !important;
}
.save_active:hover {
  background-color: var(--background-color) !important;
  /* background-color: #005fb2 !important; */
}
.close_active:hover {
  background-color: rgba(244, 246, 249, 0.75) !important;
  /* border: 1px solid #2c7be5; */
  border: 1px solid var(--button-background-color);
  color: var(--button-background-color) !important;
  /* color: #2c7be5 !important; */
}

/* Vanitha 9-11-23 */
.bill-right-container {
  background-color: white;
  color: #2d3748;
}
.billing-right-nav {
  height: max-content !important;
  padding-right: 16px !important;
  padding-left: 8px !important;
  padding-bottom: 16px !important;
  width: 100% !important;
  margin-left: 200px !important;
}
.billing-right-nav1 {
  height: max-content !important;
  padding-right: 16px !important;
  padding-left: 16px !important;
  padding-bottom: 16px !important;
  width: 100% !important;
  margin-left: 64px !important;
}

.p-r-13 {
  padding-right: 13px !important;
}
/* Vanitha 9-11-23 */

.w-91 {
  width: 91% !important;
}

.r-30 {
  right: 30% !important;
}

.m-t-18 {
  margin-top: 18px !important;
}

.insClaim_modalfooter {
  padding: 0px 16px 16px 0px !important;
  margin: 0px 16px 16px 0px !important;
  flex-wrap: nowrap !important;
  padding-left: 0% !important;
}

.w-229 {
  width: 229px !important;
}

.patpay_inv_btn {
  background-color: #75d4d0 !important;
  width: 60% !important;
  border: 1px solid #75d4d0 !important;
}

.cardTransSelectAlt .ant-select-selector {
  border: none !important;
  width: 150px !important;
}

.cardTransSelectAlt .ant-select-selection-item {
  display: flex !important;
}

.cardTransFromDate {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.cardTransToDate {
  margin-left: 20px !important;
}
.ins_background {
  background-color: transparent !important;
}
.m-l-8 {
  margin-left: 8px !important;
}

/* eob */
.allappt_search {
  padding-top: 0px !important;
  margin-top: -8px !important;
  padding-left: 0px !important;
  margin-right: 0px !important;
}

.eob_unallocate {
  width: 120px !important;
}

.width-80 {
  width: 80px !important;
}

.eob_allapt_margin {
  margin: 8px 20px 8px 20px;
  align-items: center;
}

.eob_filter {
  height: 32px;
  width: 80px;
}
/* eob */

.width-auto {
  width: auto !important;
}

.Prior_statement_table_sublist {
  font-family: var(--font-work-sans);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: black;
  text-align: left;
  margin: 20px 20px 10px 2px;
}

.for-prior-author {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.m-t-8 {
  margin-top: 8px;
}

.for-eligibility-author {
  padding-top: 3px !important;
  padding-bottom: 10px !important;
}

.Unpaid_statement_table_sublist {
  font-family: var(--font-work-sans);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: var(--button-background-color);
  text-align: left;
  margin: 17px 20px 15px 20px;
}

.bg-back1 {
  background-color: #fcfcfc;
  padding-top: 4px;
  padding-bottom: 4px;
}
.payment-entry-block {
  width: 100%;
  box-sizing: border-box;
  border-radius: 6px;
}
.payment-list-container {
  display: flex;
  flex-direction: column;
}
.payment-list-block {
  border: 1px solid #cbd5e0;
  box-sizing: border-box;
  border-radius: 6px;
  overflow: hidden;
  margin-left: 18px;
  margin-right: 20px;
  width: 100%;
}
.payment-detail-title {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
  color: black;
  text-decoration-skip-ink: none;
  padding: 15px 16px 8px;
  margin: 0 !important;
  text-align: left;
}
.payment-list-filter-block {
  background: #f5f5f5;
  margin-bottom: 8px;
  display: flex;
  flex-wrap: wrap;
  padding: 8px 12px;
  column-gap: 10px;
  row-gap: 8px;
}
.payment-list-filter-block .ant-picker:hover {
  box-shadow: none;
  border: 1px solid #e2e8ed;
}
.payment-list-filter-block .ant-input:hover {
  box-shadow: none;
  border: 1px solid #e2e8ed;
}
.payment-list-filter-block .ui.icon.input > input:hover,
.payment-list-filter-block .ui.icon.input > input:focus {
  box-shadow: none;
  border: 1px solid #e2e8ed;
}
.pat-search-for-payment-posting {
  width: 160px;
}
.eob-src-w180 {
  width: 180px;
}
.payment_posting_table {
  /* border: 1px solid #e2e8ed;
  box-sizing: border-box;
  border-radius: 6px; */
  /* margin: 0 16px 16px; */
  height: 85%;
  position: relative;
}
.pay-detail-maximise {
  float: right;
  margin-top: -30px;
  margin-right: 12px;
  height: 20px;
  width: 20px;
}
.pay-entry-minimise,
.pay-entry-maximise {
  float: right;
  margin-top: -30px;
  cursor: pointer;
  margin-right: 16px;
  height: 20px;
  width: 20px;
}
.pay_entry_table_title {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  color: var(--button-background-color);
  text-align: left;
  margin: 14px 16px;
}
.select-border-radius-4.for_autoComplete_bi .ant-select-selector {
  border-radius: 4px !important;
}
.payment-detail-container {
  /* border: 1px solid #e2e8ed;
  box-sizing: border-box;
  border-radius: 6px; */
  /* width: calc(100% - 18px); */
  height: 400px;
  /* margin: 16px 18px 0px 18px !important; */
}
.top-15 {
  top: 15%;
}
.new-paymnt-pad {
  margin-top: 0 !important;
}
.modal-p-0.modal-body {
  padding: 0;
}
.eob-doc-popup-top {
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.eob-document-popup .modal-dialog {
  min-width: 700px !important;
}
.eob-document-popup .modal-content {
  width: 700px !important;
}
.eob-document-popup thead tr th:first-child {
  padding-left: 40px;
}
.eob-document-popup tbody td:first-child {
  padding-left: 40px;
}
.popup-body-pad.modal-body {
  padding: 16px !important;
}
.popup-body-pad .form-row {
  gap: 6px;
}
.eob_doc_button {
  padding: 6px;
  font-size: 12px;
  height: 28px;
}
.m-l--16 {
  margin-left: -16px;
}
.table-text-overflow {
  width: 25px;
  max-width: 25px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.tbl-text-no-wrap {
  white-space: nowrap;
}

.eob-pay-tabs {
  width: 100%;
}
.pay-entry-save-btn {
  position: absolute;
  top: -36px;
  right: 14px;
}
.eob-pay-tabs .ant-tabs-nav {
  padding: 0px !important;
}
.pay-entry-save-btn button {
  height: 28px;
  display: flex;
  align-items: center;
}
.eob-pay-tabs .ant-tabs-tab {
  border: none !important;
  background-color: white !important;
}
.eob-pay-tabs .ant-tabs-nav-wrap {
  border-radius: 8px;
}
/* era billing */
.era_table_abv {
  display: flex;
  flex-direction: row;
}
.marg_top_8_era {
  margin-top: 8px;
}
.font_size_18 {
  font-family: Open Sans;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0px;
}
.upload_era_bttn {
  width: 120px;
  height: 32px;
}
.upload_era_bttn_showarchived {
  background-color: #2e2e2e40;
  width: 140px;
  height: 32px;
  color: black;
  border: none !important;
}
.era .btn-primary {
  border-color: unset;
}
.flex_row_spacebetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.viewbttn_era {
  height: 28px;
  top: 118px;
  left: 1031px;
  border-radius: 4px;
  border: 1px solid #2c7be5;
  background-color: unset;
  color: #2c7be5;
}
.Archivebttn_era {
  width: 80px;
  height: 28px;
  top: 118px;
  left: 1121px;
  border-radius: 4px;
  border: 1px solid #e23d28;
  background-color: unset;
  color: #e23d28;
  margin-left: 10px;
}
.era-top-btn {
  max-width: max-content;
}
.era-pat-search {
  height: 32px !important;
  width: 25%;
}
.era-top-btn {
  max-width: max-content;
}

.claimtable .ant-radio-wrapper p {
  font-size: 12px !important;
}

.claimtable .ant-checkbox-wrapper {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.claimtable .ant-checkbox + span {
  padding-right: 5px;
  padding-left: 2px;
}

.generateClaim .h-icon {
  color: rgba(44, 123, 229, 1);
  display: flex;
}

.generateClaim .save_active1 {
  background-color: rgba(19, 136, 8, 1) !important;
}

.generateClaim .back-button svg {
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
}

.generateClaim .back-button {
  padding: 0;
}

.generateClaim .ant-select {
  width: 100px !important;
}

.ins-clm-table .dropdown-item {
  border-bottom: none !important;
  font-size: 14px;
  padding: 6px;
  width: 240px;
  padding-left: 20px;
}

.insurance_claim_date {
  width: 25% !important;
  padding-left: 20px !important;
}

.claimtable .checkI {
  margin-right: 4px !important;
}

.claimtable .checkJ {
  margin-right: 3px !important;
}

.claimtable .checkL {
  margin-right: 3px !important;
}
.eob-table-overflow {
  height: 300px;
  overflow-y: auto;
  position: relative;
}
.eob-table-overflow.thead {
  position: sticky;
  top: 0;
}
.inputbox {
  width: 160px;
  height: 36px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.222055);
  border-radius: 6px;

  border: 1px solid #d8e0f0;
  padding: 10px;
  outline: none;
}

.sizedinput {
  width: 200px;
  height: 36px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.222055);
  border-radius: 6px;

  border: 1px solid #d8e0f0;
  padding: 10px;
  outline: none;
}
.sizedinputselect {
  width: 150px;
  height: 36px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.222055);
  border-radius: 6px;

  border: 1px solid #d8e0f0;
  padding: 5px;
  outline: none;
}

.sizedinputmultiselect {
  width: 200px;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.222055);
  border-radius: 6px;

  border: 1px solid #d8e0f0;
  padding: 5px;
  outline: none;
}
.sminput {
  width: 100px;
  height: 36px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.222055);
  border-radius: 6px;

  border: 1px solid #d8e0f0;
  padding: 10px;
  outline: none;
}
.textbox {
  width: 80px;
  height: 36px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.222055);
  border-radius: 6px;
  margin-bottom: 16px;
  border: 1px solid #d8e0f0;
  padding: 10px;
  outline: none;
}
.datepicker {
  width: 130px;
  height: 36px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.222055);
  border-radius: 6px;

  border: 1px solid #d8e0f0;
  padding: 10px;
  outline: none;
}

.datetimepicker {
  width: 160px;
  height: 36px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.222055);
  border-radius: 6px;
  margin-bottom: 16px;
  border: 1px solid #d8e0f0;
  padding: 10px;
  outline: none;
}
.datetimepicker svg {
  height: 20px;
  width: 20px;
  margin-top: 20px;
}
.checkbox {
  width: 16px;
  height: 16px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.222055);
  border-radius: 6px;
  margin-bottom: 16px;
  border: 1px solid #d8e0f0;
  padding: 10px;
  outline: none;
}
.radiobox {
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.radioboxGroup {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

/* tr.table-data-row.schedule-body-row td.eob-first-row{
  padding-left: 26px !important;
} */

.claim-savepopup .close_active {
  padding: 5px !important;
}

.claim-savepopup .save_active {
  border: none !important;
  border-radius: 4px !important;
  color: #ffff !important;
}

.ssn_ein {
  margin-left: 30px;
}

.claimtable td {
  padding: 0;
  padding-left: 4px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 4px;
  text-align: center !important;
}
/* eob upload */
.m_r_4 {
  margin-right: 4px;
}
.border_solid {
  border: 1px solid #2e2e2e40;
  padding: 14px;
  border-radius: 8px;
}
.upload_doc_era {
  position: absolute;
  left: 34px;
}
.p_t_12px {
  padding-top: 36px;
}
/* .w_800 {
  width: 850px !important;
  display: flex;
  justify-content: center;
} */
.up-d-mar-t-6 {
  margin-top: 6px;
}
/* .upload_width {
  width: 800px;
} */
/* .attachement-pop {
  width: 900px !important;
} */
.attachment_modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex_row_gap {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-template-rows: 1fr;
  gap: 16px;
}
.font_14px {
  font-family: Open Sans;
  font-weight: 600;
  font-size: 14px;
  padding-left: 14px;
  padding-top: 14px;
  margin-bottom: 10px;
}
#insurance_upload_popup {
  min-width: 756px;
}
.border_solid_eob {
  border-radius: 8px;
  border: 1px solid;
  border-color: #2e2e2e40;
  height: 272px;
  overflow-y: scroll;
}
.border_solid {
  border-radius: 8px;
  border: 1px solid;
  border-color: #2e2e2e40;
}
.eyeicon_blue svg {
  color: #2c7be5;
}
.delete_redicon svg {
  color: #e23d28;
}
.font_14px_upload {
  font-family: Open Sans;
  font-weight: 600;
  font-size: 14px;
  padding-left: 14px;
  margin-bottom: 10px;
}
/* eob upload end */
.ins_claim_table .dropdown {
  position: unset !important;
}
.era-check-table {
  height: 32px;
  width: 340px !important;
}
.payment_code {
  color: #5a5959 !important;
  font-weight: 600;
}
.pat-stmt-search {
  margin-right: 0px;
  margin-bottom: 8px;
}

.cursor-pointer {
  cursor: pointer;
}

.margin-left-10px {
  margin-left: 10px;
}

.margin-left-20px {
  margin-left: 20px;
}

.search-input-patient-statement
  .ant-select-selection-search-input::placeholder {
  color: red !important;
  opacity: 1 !important;
}

/* Remove border-bottom from dropdown items */
.dropdown-item {
  border-bottom: none !important;
  padding: 5px;
  font-size: 14px;
  font-weight: 400;
  padding-left: 16px;
  padding-right: 16px;
}

.dropdown-menu {
  min-width: 160px;
  border: none;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.dropdown-menu .dropdown-item:first-child {
  margin-top: 10px;
}

.dropdown-menu .dropdown-item:last-child {
  margin-bottom: 10px;
}

.patient_statement_date_filter {
  margin-left: 20px;
  max-width: 225px;
  position: relative;
  margin-left: 16px;
}

.ant-picker-input input {
  color: #2e2e2e80;
}

.pat-stmt-margin-for-icons {
  margin-right: 16px;
}

.send-email-patient-statement-pop-up {
  max-width: 400px;
  align-items: center;
}

.resize-none {
  resize: none;
}

.patient-statement-modal-buttons-size {
  width: 80px;
  padding: 5px 15px 5px 15px;
}

iframe {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.custom-select .ant-select-selector {
  border-radius: 15px !important; /* Rounded corners */
  border: none !important; /* Remove border */
  box-shadow: none !important; /* Remove any default shadow */
  outline: none !important; /* Remove focus outline */
  display: flex;
  align-items: center; /* Ensure text is vertically centered */
}

/* Adjust placeholder and selected text */
.custom-select .ant-select-selection-placeholder,
.custom-select .ant-select-selection-item {
  margin-left: 10px !important; /* Adds left margin for spacing */
}

/* Adjust the dropdown icon spacing */
.custom-select .ant-select-arrow {
  right: 10px !important; /* Moves the dropdown arrow slightly to the left */
}
.eob-width-64 {
  width: 64px;
}

.style-position-absolute {
  position: "absolute";
  margin-bottom: 10;
}

.disabled-icon {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}
.eob-ins-err-text {
  width: 162px;
  margin-top: 4px;
  line-height: 10px;
}

.eobaddpaybtn{
  margin-right: 20px !important;
}
